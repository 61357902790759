import React from 'react';
import { Progress, Stack, Td, Tr } from '@chakra-ui/react';
import FONTS from '../../../../../../../../constants/fonts';
import Text from '../../../../../../../../components/content/text/index.web';
import moment from 'moment';
import { dateFormatFrench } from '../../../../../../../../constants/date-format';
import Image from '../../../../../../../../components/content/image';
import { SignerStatus } from '../../../../../../../../services/types';
import { tKeys } from '../../../../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
export const DocumentListTableRow = ({ document, onClick, documentRelation, signatureProgression, }) => {
    // Attributes
    const { t } = useTranslation();
    const calculatedSignatureProgression = document.signers?.reduce((acc, signer) => acc + (signer?.status == SignerStatus[2] ? 1 : 0), 0);
    const procent = (calculatedSignatureProgression / document.signers?.length) *
        100;
    // Render
    return (<Tr width={'100%'} onClick={() => onClick(document)} cursor={'pointer'} role="group">
      <Td pl={'.75rem'} pr={'1rem'} py={'.75rem'}>
        <Stack height={'2.25rem'} width={'1.75rem'} minW={'1.75rem'} minH={'2.25rem'}>
          <Image borderRadius={4} imageURI={document.file?.thumbnailUri ??
            require('../../../../../../../../assets/images/pdf_preview.png')} width={'100%'} height={'100%'} isLocalImage={!document.file?.thumbnailUri}/>
        </Stack>
      </Td>
      {/* Document title */}
      <Td px={'1rem'} py={'.75rem'} maxWidth={'25rem'} _groupHover={{ textDecor: 'underline' }}>
        <Text font={FONTS.T1.T12Px.SemiBold600.value} numberOfLines={1}>
          {document?.title}
        </Text>
      </Td>
      {/* Document type On */}
      <Td px={'1rem'} py={'.75rem'}>
        <Text font={FONTS.T1.T12Px.Medium500.value} maxWidth={'20rem'} numberOfLines={3}>
          {t(document.typeName)}
        </Text>
      </Td>
      {/* Issued On */}
      <Td px={'1rem'} py={'.75rem'}>
        <Text font={FONTS.T1.T12Px.Medium500.value}>
          {moment(document.issuedOn).format(dateFormatFrench)}
        </Text>
      </Td>
      {documentRelation.value == 3 ? (<>
          <Td px={'.5rem'} py={'.75rem'} textAlign={'center'}>
            {calculatedSignatureProgression ==
                document.signers?.length ? (<Text font={FONTS.T1.T12Px.Medium500.value}>
                {' '}
                {t(tKeys.common.signed)}
              </Text>) : (<>
                <Progress value={procent} // valeur en pourcentage
             size="sm" // taille de la barre (xs, sm, md, lg)
             colorScheme="blue" // couleur du schéma (peut être "blue", "green", "red", etc.)
            />
                <Text>
                  {calculatedSignatureProgression} /{' '}
                  {document.signers?.length}
                </Text>
              </>)}
          </Td>
        </>) : (<>
          {/* Updated date */}
          <Td px={'1rem'} py={'.75rem'}>
            <Text font={FONTS.T1.T12Px.Medium500.value}>
              {moment(document.createdOn).format(dateFormatFrench)}
            </Text>
          </Td>
          {/* Event linked */}
          <Td px={'1rem'} py={'.75rem'}>
            <Text font={document.eventTitle?.length > 0
                ? FONTS.T1.T12Px.Medium500.value
                : FONTS.T1.T12Px.SemiBold600.value} maxWidth={'20rem'} numberOfLines={3}>
              {document.eventTitle?.length > 0
                ? document.eventTitle
                : t(tKeys.documents.no_event_linked)}
            </Text>
          </Td>
        </>)}
    </Tr>);
};
