/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Creates an event
 */
export const createEvent = (event, options) => {
    return customInstance({
        url: `/calendar/events`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: event,
    }, options);
};
export const useCreateEvent = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createEvent(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a list of events with filtering support
 */
export const readEvents = (params, options, signal) => {
    return customInstance({ url: `/calendar/events`, method: 'get', params, signal }, options);
};
export const getReadEventsQueryKey = (params) => [
    `/calendar/events`,
    ...(params ? [params] : []),
];
export const useReadEvents = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadEventsQueryKey(params);
    const queryFn = ({ signal, }) => readEvents(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates an event
 */
export const updateEvent = (event, options) => {
    return customInstance({
        url: `/calendar/events`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: event,
    }, options);
};
export const useUpdateEvent = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateEvent(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves an event
 */
export const readEvent = (eventId, options, signal) => {
    return customInstance({ url: `/calendar/events/${eventId}`, method: 'get', signal }, options);
};
export const getReadEventQueryKey = (eventId) => [
    `/calendar/events/${eventId}`,
];
export const useReadEvent = (eventId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadEventQueryKey(eventId);
    const queryFn = ({ signal, }) => readEvent(eventId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, { enabled: !!eventId, ...queryOptions });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Deletes an event
 */
export const deleteEvent = (eventId, options) => {
    return customInstance({ url: `/calendar/events/${eventId}`, method: 'delete' }, options);
};
export const useDeleteEvent = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId } = props ?? {};
        return deleteEvent(eventId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Downloads an ics file for a specific event
 */
export const downloadIcsFile = (eventId, options, signal) => {
    return customInstance({ url: `/calendar/events/${eventId}/ics`, method: 'get', signal }, options);
};
export const getDownloadIcsFileQueryKey = (eventId) => [
    `/calendar/events/${eventId}/ics`,
];
export const useDownloadIcsFile = (eventId, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getDownloadIcsFileQueryKey(eventId);
    const queryFn = ({ signal, }) => downloadIcsFile(eventId, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!eventId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Generates a preview of the minutes
 */
export const generateMinutesPreview = (eventId, params, options, signal) => {
    return customInstance({
        url: `/calendar/events/${eventId}/minutes/preview`,
        method: 'get',
        params,
        signal,
    }, options);
};
export const getGenerateMinutesPreviewQueryKey = (eventId, params) => [
    `/calendar/events/${eventId}/minutes/preview`,
    ...(params ? [params] : []),
];
export const useGenerateMinutesPreview = (eventId, params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ??
        getGenerateMinutesPreviewQueryKey(eventId, params);
    const queryFn = ({ signal }) => generateMinutesPreview(eventId, params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!eventId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Converts the generated preview into a PDF file and sends it to the meeting's attendees for signing
 */
export const finalizeMinutes = (eventId, params, options, signal) => {
    return customInstance({
        url: `/calendar/events/${eventId}/minutes/finalize`,
        method: 'get',
        params,
        signal,
    }, options);
};
export const getFinalizeMinutesQueryKey = (eventId, params) => [
    `/calendar/events/${eventId}/minutes/finalize`,
    ...(params ? [params] : []),
];
export const useFinalizeMinutes = (eventId, params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getFinalizeMinutesQueryKey(eventId, params);
    const queryFn = ({ signal, }) => finalizeMinutes(eventId, params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, {
        enabled: !!eventId,
        ...queryOptions,
    });
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Publish an event
 */
export const publishEvent = (eventId, options) => {
    return customInstance({ url: `/calendar/events/${eventId}/publish`, method: 'patch' }, options);
};
export const usePublishEvent = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId } = props ?? {};
        return publishEvent(eventId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Publish an event
 */
export const shareEvent = (eventId, eventShare, options) => {
    return customInstance({
        url: `/calendar/2.0/events/${eventId}/share`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: eventShare,
    }, options);
};
export const useShareEvent = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId, data } = props ?? {};
        return shareEvent(eventId, data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Upload proxy
 */
export const uploadProxy = (eventId, file, params, options) => {
    return customInstance({
        url: `/calendar/events/${eventId}/proxy`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: file,
        params,
    }, options);
};
export const useUploadProxy = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId, data, params } = props ?? {};
        return uploadProxy(eventId, data, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Generate proxy
 */
export const generateProxy = (eventId, params, options) => {
    return customInstance({
        url: `/calendar/events/${eventId}/proxy/generate`,
        method: 'post',
        params,
    }, options);
};
export const useGenerateProxy = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId, params } = props ?? {};
        return generateProxy(eventId, params, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Accept event an invitation
 */
export const acceptEventInvitation = (eventId, options) => {
    return customInstance({ url: `/calendar/events/${eventId}/accept`, method: 'post' }, options);
};
export const useAcceptEventInvitation = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId } = props ?? {};
        return acceptEventInvitation(eventId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Decline an event invitation
 */
export const declineEventInvitation = (eventId, options) => {
    return customInstance({ url: `/calendar/events/${eventId}/decline`, method: 'post' }, options);
};
export const useDeclineEventInvitation = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId } = props ?? {};
        return declineEventInvitation(eventId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Accept in remote event an invitation
 */
export const acceptInRemoteEventInvitation = (eventId, options) => {
    return customInstance({ url: `/calendar/events/${eventId}/remote`, method: 'post' }, options);
};
export const useAcceptInRemoteEventInvitation = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId } = props ?? {};
        return acceptInRemoteEventInvitation(eventId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
export const updateOccurrenceAttendee = (eventId, personId, updateOccurrenceAttendeeRequest, options) => {
    return customInstance({
        url: `/calendar/events/${eventId}/person/${personId}/presence`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: updateOccurrenceAttendeeRequest,
    }, options);
};
export const useUpdateOccurrenceAttendee = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId, personId, data } = props ?? {};
        return updateOccurrenceAttendee(eventId, personId, data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Creates an event type
 */
export const createEventType = (eventType, options) => {
    return customInstance({
        url: `/calendar/event-types`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: eventType,
    }, options);
};
export const useCreateEventType = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createEventType(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves a list of event types
 */
export const readEventTypes = (params, options, signal) => {
    return customInstance({ url: `/calendar/event-types`, method: 'get', params, signal }, options);
};
export const getReadEventTypesQueryKey = (params) => [
    `/calendar/event-types`,
    ...(params ? [params] : []),
];
export const useReadEventTypes = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadEventTypesQueryKey(params);
    const queryFn = ({ signal, }) => readEventTypes(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates an event type
 */
export const updateEventType = (eventType, options) => {
    return customInstance({
        url: `/calendar/event-types`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: eventType,
    }, options);
};
export const useUpdateEventType = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateEventType(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes an event type
 */
export const deleteEventType = (eventTypeId, options) => {
    return customInstance({ url: `/calendar/event-types/${eventTypeId}`, method: 'delete' }, options);
};
export const useDeleteEventType = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventTypeId } = props ?? {};
        return deleteEventType(eventTypeId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Creates a resolution
 */
export const createResolution = (resolution, options) => {
    return customInstance({
        url: `/calendar/resolutions`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: resolution,
    }, options);
};
export const useCreateResolution = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createResolution(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves resolutions
 */
export const readResolutions = (params, options, signal) => {
    return customInstance({ url: `/calendar/resolutions`, method: 'get', params, signal }, options);
};
export const getReadResolutionsQueryKey = (params) => [
    `/calendar/resolutions`,
    ...(params ? [params] : []),
];
export const useReadResolutions = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadResolutionsQueryKey(params);
    const queryFn = ({ signal, }) => readResolutions(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Update a resolution
 */
export const updateResolution = (resolution, options) => {
    return customInstance({
        url: `/calendar/resolutions`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: resolution,
    }, options);
};
export const useUpdateResolution = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateResolution(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes a resolution
 */
export const deleteResolution = (resolutionId, options) => {
    return customInstance({ url: `/calendar/resolutions/${resolutionId}`, method: 'delete' }, options);
};
export const useDeleteResolution = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { resolutionId } = props ?? {};
        return deleteResolution(resolutionId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Creates an agenda item
 */
export const createAgendaItem = (agendaItem, options) => {
    return customInstance({
        url: `/calendar/agenda-items`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: agendaItem,
    }, options);
};
export const useCreateAgendaItem = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return createAgendaItem(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Retrieves al list of agenda items
 */
export const readAgendaItems = (params, options, signal) => {
    return customInstance({ url: `/calendar/agenda-items`, method: 'get', params, signal }, options);
};
export const getReadAgendaItemsQueryKey = (params) => [
    `/calendar/agenda-items`,
    ...(params ? [params] : []),
];
export const useReadAgendaItems = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadAgendaItemsQueryKey(params);
    const queryFn = ({ signal, }) => readAgendaItems(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Updates an agenda item
 */
export const updateAgendaItem = (agendaItem, options) => {
    return customInstance({
        url: `/calendar/agenda-items`,
        method: 'put',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: agendaItem,
    }, options);
};
export const useUpdateAgendaItem = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return updateAgendaItem(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes an agenda item
 */
export const deleteAgendaItem = (agendaItemId, options) => {
    return customInstance({ url: `/calendar/agenda-items/${agendaItemId}`, method: 'delete' }, options);
};
export const useDeleteAgendaItem = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { agendaItemId } = props ?? {};
        return deleteAgendaItem(agendaItemId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Answer agenda item question
 */
export const answerAgendaItemQuestion = (questionId, vote, options) => {
    return customInstance({
        url: `/calendar/agenda-items/question/${questionId}/vote`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: vote,
    }, options);
};
export const useAnswerAgendaItemQuestion = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { questionId, data } = props ?? {};
        return answerAgendaItemQuestion(questionId, data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Closes an agenda item question
 */
export const closeAgendaItemQuestion = (questionId, options) => {
    return customInstance({
        url: `/calendar/agenda-items/question/${questionId}/close`,
        method: 'patch',
    }, options);
};
export const useCloseAgendaItemQuestion = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { questionId } = props ?? {};
        return closeAgendaItemQuestion(questionId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Opens an agenda item question
 */
export const openAgendaItemQuestion = (questionId, options) => {
    return customInstance({
        url: `/calendar/agenda-items/question/${questionId}/open`,
        method: 'patch',
    }, options);
};
export const useOpenAgendaItemQuestion = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { questionId } = props ?? {};
        return openAgendaItemQuestion(questionId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Deletes an agenda item question
 */
export const deleteAgendaItemQuestion = (questionId, options) => {
    return customInstance({ url: `/calendar/agenda-items/question/${questionId}`, method: 'delete' }, options);
};
export const useDeleteAgendaItemQuestion = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { questionId } = props ?? {};
        return deleteAgendaItemQuestion(questionId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Add group to occurrence
 */
export const addGroupToEvent = (occurrenceGroup, options) => {
    return customInstance({
        url: `/calendar/groups`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: occurrenceGroup,
    }, options);
};
export const useAddGroupToEvent = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { data } = props ?? {};
        return addGroupToEvent(data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Remove group from occurrence
 */
export const removeGroupFromEvent = (eventId, groupId, options) => {
    return customInstance({ url: `/calendar/events/${eventId}/groups/${groupId}`, method: 'delete' }, options);
};
export const useRemoveGroupFromEvent = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { eventId, groupId } = props ?? {};
        return removeGroupFromEvent(eventId, groupId, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
