import { t } from 'i18next';
import React from 'react';
import Placeholder from '../../../../../../components/utility/placeholder';
import { tKeys } from '../../../../../../translations/keys';
import COLORS from '../../../../../../constants/colors';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, VStack, } from '@chakra-ui/react';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import { BusyIndicator } from '../../../../../../components/utility/busy-indicator';
import { DocumentListSection } from './document-list-section/index.web';
import Text from '../../../../../../components/content/text/index.web';
import FONTS from '../../../../../../constants/fonts';
function DocumentList({ kind, listLayoutType, isDocumentDataLoading, optionsOrder, onLayoutIconPress, onDocumentPress, documentsData: data, setOrderBy, orderBy, selectedDocumentRelation, }) {
    // Render functions
    const getButtonColor = layoutType => {
        return layoutType === listLayoutType
            ? COLORS.Govrn.Blue.T500.value
            : 'transparent';
    };
    const getIconColor = layoutType => {
        return layoutType === listLayoutType ? 'white' : COLORS.Text.T300.value;
    };
    console.log(selectedDocumentRelation);
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} overflow={'auto'} height={'full'} bgColor={COLORS.BG.value}>
      <LoadingWrapper isLoading={isDocumentDataLoading}>
        <VStack width={'100%'} height={'100%'}>
          <BusyIndicator isEmpty={!data?.length} placeholder={<Placeholder imageType={'folder'} title={t(tKeys.repository.placeholder.title)} subTitle={t(tKeys.repository.placeholder.subtitle)}/>}>
            {data.length === 1 ? (<DocumentListSection key={0} data={data[0].data} kind={kind} title={data[0].title} listLayoutType={listLayoutType} onDocumentPress={onDocumentPress} setOrderBy={setOrderBy} orderBy={orderBy} optionsOrder={optionsOrder} documentRelation={selectedDocumentRelation}/>) : (<Accordion width={'100%'} allowMultiple>
                {data.map(({ title, data }, index) => (<AccordionItem key={index}>
                    <AccordionButton>
                      <Text font={FONTS.T1.T16Px.SemiBold600.value}>
                        {`${t(title)} (${data.length})`}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <DocumentListSection key={index} data={data} kind={kind} title="" listLayoutType={listLayoutType} onDocumentPress={onDocumentPress} setOrderBy={setOrderBy} orderBy={orderBy} optionsOrder={optionsOrder} documentRelation={selectedDocumentRelation}/>
                    </AccordionPanel>
                  </AccordionItem>))}
              </Accordion>)}
          </BusyIndicator>
        </VStack>
      </LoadingWrapper>
    </VStack>);
}
export default DocumentList;
