import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../../hooks/router/useRouter';
import { usePortalDocumentConfirmSMS, usePortalDocumentRequestSMS, } from '../../../../services/online/others/others';
import { downloadFileWithRename, } from '../../../../utils/downloadFile.web';
import { useToast } from '../../../../components/content/use-toast/index.web';
const useLogic = () => {
    // Attributes
    const { params } = useRouter();
    const { t } = useTranslation();
    const continueButtonRef = useRef(null);
    const { mutateAsync: requestSms, isLoading: isRequestSmsLoading } = usePortalDocumentRequestSMS();
    const { mutateAsync: confirmSms, isLoading: isConfirmSmsLoading } = usePortalDocumentConfirmSMS();
    const toast = useToast();
    // Queries
    //   const {
    //     mutateAsync: authenticatePerson,
    //     isLoading: isAuthenticatePersonLoading,
    //   } = useAuthenticatePerson();
    // coming from url params
    // Parse query parameters from the URL
    const shortPhoneNumber = params?.shortPhoneNumber;
    const documentName = params?.documentName;
    const documentId = params?.documentId;
    const eventId = params?.eventId;
    const versionId = params?.versionId;
    const userId = params?.userId;
    const [smsCode, setSmsCode] = useState('');
    const [smsSent, setSmsSent] = useState(false);
    const [isDocumentDownloaded, setIsDocumentDownloaded] = useState(false);
    const handleSubmit = async () => {
        await requestSms({
            documentId,
            data: {
                documentId,
                eventId,
                versionId,
                userId,
            },
        });
        setSmsSent(true);
    };
    const handleSubmitSmsCode = async () => {
        confirmSms({
            documentId,
            data: {
                documentId,
                eventId,
                versionId,
                userId,
                code: smsCode,
            },
        })
            .then(response => {
            downloadFileWithRename(response.documentSignedUrl, documentName);
            setIsDocumentDownloaded(true);
        })
            .catch(error => {
            toast({
                title: 'Sorry you send the wrong code',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        });
    };
    return {
        shortPhoneNumber,
        documentName,
        handleSubmit,
        handleSubmitSmsCode,
        smsCode,
        smsSent,
        setSmsCode,
        isRequestSmsLoading,
        isConfirmSmsLoading,
        isDocumentDownloaded,
    };
};
export default useLogic;
