import React from 'react';
import Card from '../../../../../../components/surfaces/card/index.web';
import { PostKind } from '../../../../../../components/surfaces/share-in-private-card/props';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { postTypes } from '../../../types';
import CardFooter from '../../surfaces/card/card-footer';
import CardHeader from '../../surfaces/card/card-header/index.web';
import CardPost from '../../surfaces/card/card-post/index.web';
import CardPostDocument from '../../surfaces/card/card-post-document/index.web';
import CardPostInvitation from '../../surfaces/card/card-post-invitation/index.web';
import CardPostPoll from '../../surfaces/card/card-post-poll/index.web';
import useLogic from './logic.web';
import styles from './style';
import EditPost from '../../../edit-post/index.web';
const FeedListItem = ({ item, index, getPostType, currentUserId, handleOnPressMessage, companyData, selectedEnrolment, isNewsFeedDataLoading, refetchNewsFeedData, footerSeparator, isPostDetails, }) => {
    // Attributes
    const { handleDeletePost, handleEditPost, handleIconPress, showPostModal, creator, isCreatorLoading, isPostLikedByUser, handleVote, handleLike, isVoteLoading, isDeletePostLoading, handleOnPressMember, isOpenEditPost, onCloseEditPost, } = useLogic({
        post: item,
        currentUserId,
        company: companyData,
        refetchNewsFeedData,
    });
    // Render post by category
    function renderByCategory() {
        switch (getPostType(item)) {
            case postTypes.Post: {
                const itemPost = item;
                // Render post items
                return (<CardPost isPostDetails={isPostDetails} styles={{
                        ...styles?.cardContainerContainer,
                    }} index={`${itemPost?.id} - ${index}`} itemPost={itemPost} creator={creator} currentUserId={currentUserId} handleOnPressMessage={() => handleOnPressMessage(itemPost, PostKind.POST)} isCreatorLoading={isCreatorLoading} isPostLikedByUser={isPostLikedByUser} handleDeletePost={handleDeletePost} isDeletePostLoading={isDeletePostLoading} handleDismissModal={() => undefined} handleEditPost={handleEditPost} handleIconPress={handleIconPress} showPostModal={showPostModal} handleLike={handleLike} onPressMemberName={handleOnPressMember} footerSeparator={footerSeparator} handleOnPostPress={() => undefined}/>);
            }
            case postTypes.PostOfDocument: {
                const itemPostOfDocument = item;
                // Render post of document
                return (<CardPostDocument isPostDetails={isPostDetails} currentUserId={currentUserId} itemPostOfDocument={itemPostOfDocument} creator={creator} index={`${itemPostOfDocument?.id} - ${index}`} styles={{
                        ...styles.cardContainerContainer,
                    }} handleOnPressMessage={() => handleOnPressMessage(itemPostOfDocument, PostKind.FILE)} isCreatorLoading={isCreatorLoading} isPostLikedByUser={isPostLikedByUser} handleDeletePost={handleDeletePost} isDeletePostLoading={isDeletePostLoading} handleDismissModal={() => undefined} handleEditPost={handleEditPost} handleIconPress={handleIconPress} showPostModal={showPostModal} handleLike={handleLike} onPressMemberName={handleOnPressMember} footerSeparator={footerSeparator} handleOnPostPress={() => undefined}/>);
            }
            case postTypes.PostOfInvitation: {
                const itemPostOfInvitation = item;
                // Render post of invitation
                return (<CardPostInvitation isPostDetails={isPostDetails} styles={styles.cardContainerContainer} companyName={companyData?.name} companyImageURI={selectedEnrolment?.company?.picture?.uri} item={itemPostOfInvitation} creator={creator} index={index} footerSeparator={footerSeparator} handleOnPostPress={() => undefined}/>);
            }
            case postTypes.PostOfQuestion: {
                const itemPostOfQuestion = item;
                // Render post of question (poll)
                return (<CardPostPoll isPostDetails={isPostDetails} styles={styles.cardContainerContainer} index={`${itemPostOfQuestion?.id} - ${index}`} handleOnPressMessage={() => handleOnPressMessage(itemPostOfQuestion, PostKind.POLL)} itemPostOfQuestion={itemPostOfQuestion} currentUserId={currentUserId} isNewsFeedDataLoading={isNewsFeedDataLoading} creator={creator} isCreatorLoading={isCreatorLoading} isPostLikedByUser={isPostLikedByUser} handleDeletePost={handleDeletePost} isDeletePostLoading={isDeletePostLoading} handleDismissModal={() => undefined} handleEditPost={handleEditPost} handleIconPress={handleIconPress} showPostModal={showPostModal} handleVote={handleVote} isVoteLoading={isVoteLoading} handleLike={handleLike} onPressMemberName={handleOnPressMember} footerSeparator={footerSeparator} handleOnPostPress={() => undefined}/>);
            }
            default: {
                <Card isPostDetails={isPostDetails} backgroundColor={[
                        COLORS.Govrn.DarkBlue.T300.value,
                        COLORS.Tags.BluePurple.value,
                    ]} header={<CardHeader imageUri={undefined} title={item?.content} titleFont={FONTS.T1.T14Px.SemiBold600.value} titleColor={COLORS.White.T500.value} imageHeight={30} imageWidth={40} titleNbOfLines={3} titleMaxWidth={'90%'}/>} footer={<CardFooter date={new Date()} dateTextColor={COLORS.White.T500.value} footerSeparator={footerSeparator}/>}/>;
            }
        }
    }
    // Render
    return (<>
      {renderByCategory()}
      {/* Modals */}
      <EditPost isOpen={isOpenEditPost} onClose={onCloseEditPost} itemPost={item}/>
    </>);
};
export default FeedListItem;
