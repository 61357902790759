/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Govrn API
 * Generic rework of Govrn's API
 * OpenAPI spec version: v0.0.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import { customInstance } from '.././config';
/**
 * @summary Retrieves a list of time zones
 */
export const readTimeZones = (params, options, signal) => {
    return customInstance({ url: `/others/timezones`, method: 'get', params, signal }, options);
};
export const getReadTimeZonesQueryKey = (params) => [
    `/others/timezones`,
    ...(params ? [params] : []),
];
export const useReadTimeZones = (params, options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadTimeZonesQueryKey(params);
    const queryFn = ({ signal, }) => readTimeZones(params, requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Retrieves a list of cultures
 */
export const readCultures = (options, signal) => {
    return customInstance({ url: `/others/cultures`, method: 'get', signal }, options);
};
export const getReadCulturesQueryKey = () => [`/others/cultures`];
export const useReadCultures = (options) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? getReadCulturesQueryKey();
    const queryFn = ({ signal, }) => readCultures(requestOptions, signal);
    const query = useQuery(queryKey, queryFn, queryOptions);
    query.queryKey = queryKey;
    return query;
};
/**
 * @summary Post SMS for document download on event
 */
export const portalDocumentRequestSMS = (documentId, postSMSRequest, options) => {
    return customInstance({
        url: `/portal/documents/${documentId}/sms`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: postSMSRequest,
    }, options);
};
export const usePortalDocumentRequestSMS = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { documentId, data } = props ?? {};
        return portalDocumentRequestSMS(documentId, data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
/**
 * @summary Post SMS for document download on event
 */
export const portalDocumentConfirmSMS = (documentId, postSMSConfirmRequest, options) => {
    return customInstance({
        url: `/portal/documents/${documentId}/sms-confirm`,
        method: 'post',
        headers: { 'Content-Type': 'application/json-patch+json' },
        data: postSMSConfirmRequest,
    }, options);
};
export const usePortalDocumentConfirmSMS = (options) => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};
    const mutationFn = props => {
        const { documentId, data } = props ?? {};
        return portalDocumentConfirmSMS(documentId, data, requestOptions);
    };
    return useMutation(mutationFn, mutationOptions);
};
