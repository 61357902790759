import { useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilePicker } from 'use-file-picker';
import { useToast } from '../../components/content/use-toast/index.web';
import { PostKind } from '../../components/surfaces/share-in-private-card/props';
import { useCompaniesContext } from '../../contexts/companies/index.web';
import useRouter from '../../hooks/router/useRouter';
import { useReadEvent } from '../../services/online/calendar/calendar';
import { useReadEnrolments } from '../../services/online/enrolments/enrolments';
import { useReadTemplates } from '../../services/online/knowledge-base/knowledge-base';
import { getReadDocumentHistoryQueryKey, getReadDocumentQueryKey, getReadDocumentsQueryKey, useDeleteDocument, usePostRemindDocumentSignature, useReadDocument, useReadDocumentHistory, useReadDocumentPermissions, useReadDocumentTypes, useUpdateDocument, } from '../../services/online/repository/repository';
import { getReadTasksQueryKey } from '../../services/online/tasks/tasks';
import { tKeys } from '../../translations/keys';
import { arrayBufferToBase64 } from '../../utils/arrayBuffer.web';
import { downloadFile, downloadFileToPDF, downloadFileWithRename, downloadMinutes, } from '../../utils/downloadFile.web';
import getLanguageValue from '../../utils/getLanguageValue';
import getStaleTime from '../../utils/getStaleTime';
import { extractBase64FromDataUrl, onSelectedFileSuccess, } from '../../utils/selectFile.web';
import { mapToDocumentEntityFromDocument, mapToDocumentEntityFromMinutes, mapToDocumentEntityFromTemplate, } from './mappers';
import { DocumentKindEnum, } from './type';
const useLogic = () => {
    // Attributes
    const [selectedCulture, setSelectedCulture] = useState();
    const [isAddSignersLoading, setisAddSignersLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const [searchSigner, setSearchSigner] = useState('');
    const [pdftronWebviewerInstance, setPdftronWebviewInstance] = useState();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const permissionsDisclosure = useDisclosure();
    const { isOpen: isOpenDocumentViewer, onClose: onCloseDocumentViewer, onOpen: onOpenDocumentViewer, } = useDisclosure();
    const { isOpen: isOpenSignDocument, onClose: onCloseSignDocument, onOpen: onOpenSignDocument, } = useDisclosure();
    const { isOpen: isOpenDocumentEdit, onClose: onCloseDocumentEdit, onOpen: onOpenDocumentEdit, } = useDisclosure();
    const { isOpen: isOpenShareInMessage, onOpen: onOpenShareInMessage, onClose: onCloseShareInMessage, } = useDisclosure();
    const { isOpen: isOpenWebView, onOpen: onOpenWebView, onClose: onCloseWebView, } = useDisclosure();
    const [selectedDocument, setSelectedDocument] = useState();
    const [selectedDocumentToSign, setSelectedDocumentToSign] = useState();
    const [documentToEdit, setDocumentToEdit] = useState();
    const [shareInMessageEntity, setShareInMessageEntity] = useState();
    const [currentDocumentVersionId, setCurrentDocumentVersionId] = useState();
    const [choosenDocument, setChoosendDocument] = useState();
    const toast = useToast();
    const { params, goBack } = useRouter();
    // Query params
    const { documentKind, documentId, eventId, taskType, template, culture } = params ?? {};
    const isDocumentKind = documentKind !== DocumentKindEnum.TEMPLATE;
    // Queries - Getters
    const { me } = useCompaniesContext();
    const { data: documentDetails, isLoading: isDocumentLoading, refetch: refetchDoc, } = useReadDocument(documentId, { 'version-id': currentDocumentVersionId }, {
        query: {
            enabled: !!documentId && !!currentDocumentVersionId && isDocumentKind,
            keepPreviousData: true,
            onSuccess: document => {
                setSelectedDocument({
                    documentUri: document?.file?.uri,
                    documentTitle: document?.file?.filename,
                    document,
                });
            },
        },
    });
    const { data: permissions, isLoading: isPermissionsLoading, refetch: refetchPermissions, } = useReadDocumentPermissions(documentDetails?.id ?? '', {
        query: { enabled: !!documentDetails?.id },
    });
    const { mutateAsync: updateDocument, isLoading: isUpdateDocumentLoading } = useUpdateDocument();
    const { mutateAsync: sendReminderSignature, isLoading: isSendReminderSignatureLoading, } = usePostRemindDocumentSignature();
    const { data: documentHistory, isLoading: isHistoryLoading } = useReadDocumentHistory(documentId, {
        query: {
            enabled: !!documentId && isDocumentKind,
            onSuccess: documentHistory => {
                setCurrentDocumentVersionId(documentHistory[0]?.versionId);
            },
        },
    });
    const { data: event, isLoading: isEventLoading } = useReadEvent(eventId ?? documentDetails?.eventId, {
        query: {
            enabled: !!eventId || (!!documentDetails?.eventId && isDocumentKind),
        },
    });
    const { data: enrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'show-intactive': true,
    }, { query: { enabled: !!documentDetails?.creatorId && isDocumentKind } });
    const { data: searchedEnrolments } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
        'search-term': searchSigner,
    }, { query: { enabled: !!documentDetails?.creatorId && isDocumentKind } });
    const { mutateAsync: deleteDocument, isLoading: isDeleteDocumentLoading } = useDeleteDocument();
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, {
        query: {
            enabled: !!selectedCompanyId && isDocumentKind,
            staleTime: getStaleTime(),
        },
    });
    const { data: templatesData, isLoading: isTemplatesLoading } = useReadTemplates({
        culture: 'en-BE',
        'sort-by': 3,
    }, {
        query: { enabled: documentKind === DocumentKindEnum.TEMPLATE },
    });
    const templatesList = templatesData?.flatMap(template => template.items);
    const selectedTemplate = templatesList?.find(template => template.id === documentId);
    const { openFilePicker } = useFilePicker({
        readAs: 'DataURL',
        accept: [
            'image/jpeg',
            'image/png',
            'image/gif',
            '.txt',
            '.pdf',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.xls',
            '.xlsx',
            '.csv',
        ],
        onFilesSelected: async (chosenDocuments) => await onSelectedFileSuccess(chosenDocuments, handleOnSelectFile),
    });
    async function refetchThePermissions() {
        await refetchPermissions();
    }
    // Effects
    useEffect(() => {
        setSelectedCulture(culture ?? me?.cultureName ?? getLanguageValue('English'));
    }, [culture, me]);
    // Entity
    const documentEntity = useMemo(() => {
        if (documentKind === DocumentKindEnum.DOCUMENT || documentDetails)
            return mapToDocumentEntityFromDocument(documentDetails, taskType, event);
        if (documentKind === DocumentKindEnum.TEMPLATE)
            return mapToDocumentEntityFromTemplate(template);
        if (documentKind === DocumentKindEnum.MINUTES)
            return mapToDocumentEntityFromMinutes(documentDetails, event);
    }, [documentDetails, taskType, event, documentHistory, template]);
    // Functions
    function refetchDocument() {
        void refetchDoc();
    }
    function handleClickNewFile() {
        openFilePicker();
    }
    function handleOnSelectFile(value) {
        setChoosendDocument(value);
    }
    function handleChooseVersion(versionId) {
        setCurrentDocumentVersionId(versionId);
        refetchDoc();
    }
    function handleSetWebviewerInstance(value) {
        setPdftronWebviewInstance(value);
    }
    function handleCloseWebView() {
        onCloseWebView();
        refetchDocument();
    }
    function handleSetSearch(value) {
        setSearchSigner(value);
    }
    const handleOnPressDelete = () => {
        void deleteDocument({ documentId }, {
            onSuccess: () => {
                void queryClient.invalidateQueries(getReadDocumentsQueryKey({
                    'event-id': documentDetails?.eventId,
                    'document-types': documentTypes?.map(d => d?.id),
                    'company-id': selectedCompanyId,
                }));
                goBack();
            },
        });
    };
    const userCanEditDocument = documentDetails?.creatorId === me?.id;
    function handleOnPressMessage() {
        const shareInMessageEntity = {
            data: documentDetails,
            type: PostKind?.FILE,
            isDocument: true,
        };
        setShareInMessageEntity(shareInMessageEntity);
        onOpenShareInMessage();
    }
    function handleSendReminder(personId) {
        sendReminderSignature({
            documentId: documentDetails?.id,
            data: {
                peopleIds: [personId],
                versionId: documentDetails?.versionId,
            },
        }, {
            onSuccess: () => {
                toast({
                    status: 'success',
                    title: t(tKeys.document_viewer.sign_reminder.success),
                });
                refetchDocument();
            },
            onError: () => {
                toast({
                    status: 'error',
                    title: t(tKeys.document_viewer.sign_reminder.error),
                });
            },
        });
    }
    function handleOnPressEditDocument() {
        const editDocumentTypeEntity = {
            allFileTypes: null,
            changeLog: documentDetails?.changeLog ?? '',
            description: documentDetails?.description ?? '',
            documentId: documentDetails?.id ?? '',
            file: documentDetails?.file ?? null,
            selectedDocumentType: null,
            title: documentDetails?.title ?? '',
            typeId: documentDetails?.typeId ?? '',
            signers: documentDetails?.signers,
            issuedOn: documentDetails?.issuedOn ?? '',
            groupIds: documentDetails?.groupIds ?? [],
            personIds: documentDetails?.personIds ?? [],
        };
        setDocumentToEdit(editDocumentTypeEntity);
        onOpenDocumentEdit();
    }
    async function handleOnDownloadPress(isOriginal, withAnnotations) {
        setIsDownloading(true);
        try {
            if (isOriginal) {
                switch (documentEntity?.kind) {
                    case DocumentKindEnum.DOCUMENT:
                        await downloadFileWithRename(documentDetails?.file.uri, documentDetails?.title);
                        break;
                    case DocumentKindEnum.TEMPLATE:
                        await downloadFile(selectedTemplate?.file.uri);
                        break;
                    case DocumentKindEnum.MINUTES:
                        await downloadMinutes(eventId ?? documentDetails?.eventId, selectedCulture);
                }
                return;
            }
            // Watermark
            const watermark = documentDetails?.isWatermarkingEnabled
                ? `${me?.firstname} ${me?.lastname}`
                : undefined;
            await downloadFileToPDF(documentEntity?.file?.filename, pdftronWebviewerInstance, withAnnotations, watermark);
        }
        catch (err) {
            toast({
                status: 'error',
                title: t(tKeys.validation.document.download.error),
            });
            console.error(err);
        }
        finally {
            setIsDownloading(false);
        }
    }
    const onFullScreenPress = () => {
        onOpenDocumentViewer();
        if (documentKind === DocumentKindEnum.DOCUMENT ||
            documentKind === DocumentKindEnum.MINUTES) {
            setSelectedDocument({
                documentUri: documentDetails?.file?.uri,
                documentTitle: documentDetails?.file?.filename,
                document: documentDetails,
            });
            return;
        }
        setSelectedDocument({
            documentUri: template?.file?.uri,
            documentTitle: template?.file?.filename,
            document: template,
        });
    };
    const onOpenDocumentVersion = (document) => {
        onOpenDocumentViewer();
        setSelectedDocument({
            documentUri: document?.file?.uri,
            documentTitle: document?.file?.filename,
            document,
        });
    };
    function handleSignDocument() {
        setSelectedDocumentToSign(documentDetails);
        onOpenSignDocument();
    }
    async function handleAddSigners(signerIds) {
        if (documentDetails?.signerIds.length || !signerIds.length)
            return;
        setisAddSignersLoading(true);
        try {
            if (!documentDetails.file?.mimeType.includes('pdf')) {
                const { documentViewer, officeToPDFBuffer } = pdftronWebviewerInstance?.Core;
                const fileName = documentViewer?.getDocument()?.getFilename();
                const docBuffer = await officeToPDFBuffer(documentDetails?.file?.uri, undefined);
                const file = documentDetails?.file;
                file.mimeType = 'application/pdf';
                file.content = arrayBufferToBase64(docBuffer);
                file.filename = `${fileName}.pdf`;
                return await handleUpdateDocument(signerIds, file);
            }
            return await handleUpdateDocument(signerIds);
        }
        finally {
            setisAddSignersLoading(false);
        }
    }
    async function handleUpdateDocument(signerIds, newFile) {
        await updateDocument({
            data: {
                changeLog: documentDetails?.changeLog ?? '',
                companyId: documentDetails?.companyId,
                createdOn: documentDetails?.createdOn,
                creatorId: documentDetails?.creatorId,
                description: documentDetails?.description ?? '',
                eventId: documentDetails?.eventId,
                file: newFile ?? documentDetails?.file,
                id: documentDetails?.id,
                isDownloadable: documentDetails?.isDownloadable,
                messageId: documentDetails?.messageId,
                signerIds: signerIds === undefined ? documentDetails?.signerIds : signerIds,
                title: documentDetails?.title,
                typeId: documentDetails?.typeId,
                versionId: documentDetails?.versionId,
                xfdfString: documentDetails?.xfdfString,
            },
        });
        await queryClient.invalidateQueries(getReadDocumentQueryKey(documentDetails?.id));
        await queryClient.invalidateQueries(getReadDocumentHistoryQueryKey(documentDetails?.id));
        await refetchDoc();
    }
    async function handleUploadNewVersion(newFile) {
        await handleUpdateDocument([], newFile);
    }
    const isUserMustSignDocument = useMemo(() => {
        if (!documentDetails)
            return false;
        return (documentDetails?.signers?.find(signer => signer?.personId === me?.id && signer?.status === 1) !== undefined);
    }, [documentDetails, me]);
    // Function
    const isUserAlreadySigned = useCallback((personId) => {
        return (documentEntity?.signers?.find(signer => signer?.personId === personId)
            ?.status !== 1);
    }, [documentEntity]);
    function handleOnCloseShareInMessage() {
        goBack();
        onCloseShareInMessage();
    }
    function handleGoBack() {
        void queryClient.invalidateQueries(getReadTasksQueryKey(undefined));
        goBack();
    }
    function fetchRedirectLink() {
        const currentUserActionId = documentDetails?.signers?.find(signer => signer?.personId === me?.id)?.actionId;
        return `https://apps.universign.com/npds/${currentUserActionId}`;
    }
    function handleOnClickSign() {
        onOpenWebView();
        onCloseSignDocument();
    }
    const currentDocumentType = useMemo(() => {
        return documentTypes?.find(type => type?.id === documentDetails?.typeId);
    }, [documentTypes, documentDetails]);
    const creator = useMemo(() => {
        if (documentDetails?.creatorId === me?.id)
            return me;
        return enrolments?.items?.find(enrolment => enrolment?.person?.id === documentDetails?.creatorId)?.person;
    }, [enrolments, documentDetails?.creatorId]);
    const documentCanHaveSigners = !documentDetails?.file?.mimeType?.includes('image');
    // Effects
    useEffect(() => {
        if (!documentEntity)
            return;
        setTimeout(() => (document.title = `${selectedEnrolment.company?.name} | ${t(documentEntity?.title)}`), 200);
    }, [documentEntity?.title, selectedEnrolment]);
    useEffect(() => {
        // if (
        //   !pdftronWebviewerInstance ||
        //   documentDetails?.versionId == currentDocumentVersionId ||
        //   !documentDetails ||
        //   isDocumentLoading
        // )
        //   return;
        // const { documentViewer } = pdftronWebviewerInstance?.Core;
        // // Close previous document
        // void documentViewer?.closeDocument();
        // // Load the new document
        // void documentViewer?.loadDocument(documentDetails?.file?.uri);
        // // Import annotations
        // const annotationsManager = documentViewer.getAnnotationManager();
        // documentViewer.addEventListener('documentLoaded', () => {
        //   void annotationsManager.importAnnotationCommand(
        //     documentDetails?.xfdfString
        //   );
        // });
    }, [documentDetails]);
    useEffect(() => {
        if (!choosenDocument || isUpdateDocumentLoading)
            return;
        const file = {
            content: '',
            filename: '',
            mimeType: '',
            thumbnailUri: '',
            uri: '',
        };
        if (choosenDocument?.documentMetaData?.mime) {
            file.mimeType = choosenDocument?.documentMetaData?.mime;
            file.uri = '';
            const imageBase64 = extractBase64FromDataUrl(choosenDocument?.data);
            file.content = imageBase64;
        }
        else {
            file.content = choosenDocument?.data;
            file.mimeType = choosenDocument?.documentMetaData?.type;
            file.filename = choosenDocument?.documentMetaData?.name;
        }
        void handleUploadNewVersion(file);
    }, [choosenDocument]);
    const documentLoading = (!!documentId && isDocumentLoading) || (!!eventId && isEventLoading);
    const templateLoading = isTemplatesLoading;
    const pageLoading = isDocumentKind ? documentLoading : templateLoading;
    const isPDF = documentDetails?.file?.mimeType?.includes('pdf');
    // Return
    return {
        handleOnPressMessage,
        onFullScreenPress,
        isLoading: pageLoading,
        isHistoryLoading: !!documentId && isHistoryLoading,
        onOpenDocumentVersion,
        isDownloading,
        documentEntity,
        documentHistory,
        selectedCulture,
        userCanEditDocument,
        handleOnPressEditDocument,
        isUserMustSignDocument,
        handleSignDocument,
        isUserAlreadySigned,
        me,
        isOpenDocumentViewer,
        onCloseDocumentViewer,
        selectedDocument,
        isOpenSignDocument,
        onCloseSignDocument,
        selectedDocumentToSign,
        isOpenDocumentEdit,
        onCloseDocumentEdit,
        documentToEdit,
        refetchDocument,
        isOpenShareInMessage,
        shareInMessageEntity,
        handleOnCloseShareInMessage,
        onCloseShareInMessage,
        fetchRedirectLink,
        handleOnClickSign,
        isOpenWebView,
        handleOnDownloadPress,
        handleGoBack,
        handleOnPressDelete,
        isDeleteDocumentLoading,
        documentDetails,
        currentDocumentType,
        creator,
        event,
        isUpdateDocumentLoading,
        handleAddSigners,
        documentCanHaveSigners,
        searchedEnrolments,
        handleSetSearch,
        searchSigner,
        handleCloseWebView,
        handleSetWebviewerInstance,
        handleChooseVersion,
        currentDocumentVersionId,
        handleClickNewFile,
        selectedTemplate,
        isPDF,
        isPermissionsLoading,
        permissions,
        permissionsDisclosure,
        refetchThePermissions,
        isAddSignersLoading,
        handleSendReminder,
        isSendingReminder: isSendReminderSignatureLoading,
    };
};
export default useLogic;
