import { StyleSheet } from 'react-native';
import COLORS from '../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.BG.value,
        flex: 1,
        flexGrow: 1,
        paddingHorizontal: 32,
    },
    tabsContainer: {
        marginTop: -2,
        width: '100%',
    },
});
export default styles;
