import { Box, Divider, HStack, Stack, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../../../components/content/text';
import Container from '../../../../../../components/surfaces/container';
import LoadingAnimation from '../../../../../../components/utility/loading';
import Placeholder from '../../../../../../components/utility/placeholder';
import COLORS from '../../../../../../constants/colors';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import CreatePost from '../../../create-post/index.web';
import FeedListItem from '../news-feed-list-item';
import styles from './style.web';
const NewsFeedList = ({ me, markPostAsRead, newsFeedData, companyData, selectedEnrolment, currentPageNewsFeed, isNewsFeedDataLoading, isNewsFeedDataFetching, getPostType, handleOnPressMessage, fetchNextPageNewsFeedData, refetchNewsFeedData, activeFiltersNumber, }) => {
    // Attributes
    const { t } = useTranslation();
    const displayLoadingAnimation = isNewsFeedDataFetching && !!currentPageNewsFeed && currentPageNewsFeed > 2;
    // Functions
    const handleScroll = (e) => {
        const stack = e.target;
        const bottom = stack.scrollTop +
            1 +
            stack.clientHeight >=
            stack.scrollHeight;
        if (bottom && !isNewsFeedDataFetching) {
            void fetchNextPageNewsFeedData();
        }
    };
    const data = useMemo(() => newsFeedData?.pages?.flatMap(nextPageData => nextPageData?.items) ?? [], [newsFeedData]);
    // Render
    return (<VStack height="94vh" width={'full'} overflow={'auto'} backgroundColor={COLORS.BG.value} onScroll={handleScroll} px={'2rem'} pt={'1.5rem'}>
      <VStack w="full" alignSelf={'flex-start'}>
        <Box maxWidth={'40rem'} w="full">
          <CreatePost refetchNewsFeedData={refetchNewsFeedData}/>
        </Box>
        <HStack justify="space-evenly" width={'full'} py={'1rem'}>
          <Divider />

          <Stack px={'1rem'} minWidth={'fit-content'}>
            <Text color={COLORS.InputText.value} font={FONTS.T1.T12Px.Regular400.value} width={'100%'}>
              {t(tKeys.page.menu['news-feed'])}
            </Text>
          </Stack>

          <Divider />
        </HStack>
        <VStack width={'100%'} maxWidth={'40rem'} alignItems={'flex-start'} pb={'1.5rem'} spacing={'.75rem'}>
          {!data && !isNewsFeedDataFetching && !isNewsFeedDataLoading ? (<Placeholder imageType={'think-emoji'} title={activeFiltersNumber
                ? t(tKeys.news_feed.no_post_filtered.placeholder.title)
                : t(tKeys.news_feed.no_post.placeholder.title)} subTitle={activeFiltersNumber
                ? t(tKeys.news_feed.no_post_filtered.placeholder.subtitle)
                : t(tKeys.news_feed.no_post.placeholder.subtitle)} backgroundColor={'#CFD2E0'} style={styles.placeholder}/>) : (<>
              {data?.map((item, index) => (<FeedListItem item={item} index={index} getPostType={getPostType} currentUserId={me?.id} handleOnPressMessage={handleOnPressMessage} companyData={companyData} selectedEnrolment={selectedEnrolment} isNewsFeedDataLoading={isNewsFeedDataLoading} refetchNewsFeedData={refetchNewsFeedData} key={index}/>))}
              {displayLoadingAnimation && (<Container alignSelf="center" margin={16}>
                  <LoadingAnimation />
                </Container>)}
            </>)}
        </VStack>
      </VStack>
    </VStack>);
};
export default NewsFeedList;
