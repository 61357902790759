import { HStack, Stack, VStack } from '@chakra-ui/react';
import Button from '../../../../../components/inputs/button/index.web';
import React from 'react';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { useTranslation } from 'react-i18next';
import { tKeys } from '../../../../../translations/keys';
import PopOver from '../../../../../components/surfaces/pop-over/index.web';
import Icon from '../../../../../components/content/icon';
import { Size } from '../../../../../components/inputs/button/constants/size';
import Text from '../../../../../components/content/text';
import FONTS from '../../../../../constants/fonts';
import LoadingAnimation from '../../../../../components/utility/loading/index.web';
import COLORS from '../../../../../constants/colors';
export const DocumentDetailsHeader = ({ isDownloadable, isTemplate, isDownloading, handleDownloadPress, handleOnPressMessage, userCanEditDocument, handleOnPressEditDocument, handleOnPressDelete, isDeleteDocumentLoading, isOriginalFileDownloadable, isPDF, handleDocumentPermissionsOpen, }) => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<HStack spacing={'1rem'}>
      {(isDownloadable || isTemplate) && (<>
          {isTemplate ? (<Button kind={Kind.SECONDARY} isLoading={isDownloading} onPress={() => handleDownloadPress(true, false)}>
              <HStack>
                <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                  {t(tKeys.common.download)}
                </Text>
              </HStack>
            </Button>) : (<PopOver from={<Button kind={Kind.SECONDARY} isLoading={isDownloading}>
                  <HStack>
                    <Text color={COLORS.White.T500.value} font={FONTS.T1.T12Px.SemiBold600.value}>
                      {t(tKeys.common.download)}
                    </Text>
                    <Icon name="chevron-down" stroke={COLORS.White.T500.value} height={20} width={20}/>
                  </HStack>
                </Button>}>
              <VStack py={'.5rem'} spacing={0}>
                {isOriginalFileDownloadable && !isPDF && (<Stack onClick={() => handleDownloadPress(true, false)} cursor={'pointer'} _hover={{ bgColor: COLORS.Tag.value }} p={'.5rem'} width={'full'}>
                    <Text>{t(tKeys.common['download-original'])}</Text>
                  </Stack>)}
                <Stack onClick={() => handleDownloadPress(isPDF, false)} cursor={'pointer'} _hover={{ bgColor: COLORS.BG.value }} p={'.5rem'} width={'full'}>
                  <Text>{t(tKeys.common['download-pdf'])}</Text>
                </Stack>
                <Stack onClick={() => handleDownloadPress(false, true)} cursor={'pointer'} _hover={{ bgColor: COLORS.BG.value }} p={'.5rem'} width={'full'}>
                  <Text>{t(tKeys.common['download-annotations'])}</Text>
                </Stack>
              </VStack>
            </PopOver>)}
        </>)}
      {/* Share the event in private message */}
      <Button kind={Kind.OUTLINE} onPress={handleOnPressMessage}>
        {t(tKeys.common.share)}
      </Button>
      {userCanEditDocument && (<PopOver from={<Icon name="more-vertical" height={20} width={20}/>} placement="bottom-start">
          <VStack spacing={4} align="stretch" px={'.5rem'} py={'.25rem'}>
            {/* Permissions button */}
            <Button onPress={handleDocumentPermissionsOpen} width={'full'} kind={Kind.TRANSPARENT} size={Size.COMPACT}>
              <HStack width={'full'}>
                <Icon name="permissions" width={30} height={30}/>

                <Text font={FONTS.T1.T14Px.Medium500.value}>
                  {t(tKeys.common.permissions)}
                </Text>
              </HStack>
            </Button>

            {/* Edit document button */}
            <Button onPress={handleOnPressEditDocument} kind={Kind.TRANSPARENT} width={'full'} size={Size.COMPACT}>
              <HStack width={'full'}>
                <Icon name="edit" width={30} height={30} fill={'transparent'}/>
                <Text font={FONTS.T1.T14Px.Medium500.value}>
                  {t(tKeys.news_feed.edit_post)}
                </Text>
              </HStack>
            </Button>

            {/* Delete document button with confirmation */}
            <Button onPress={!isDeleteDocumentLoading && handleOnPressDelete} needConfirmation={true} // Activates the confirmation logic
         width={'full'} kind={Kind.TRANSPARENT} size={Size.COMPACT}>
              <HStack width={'full'}>
                {isDeleteDocumentLoading ? (<LoadingAnimation size={Size.MINI}/>) : (<Icon name="delete-bin" width={30} height={30}/>)}

                <Text font={FONTS.T1.T14Px.Medium500.value}>
                  {t(tKeys.news_feed.delete_post.title)}
                </Text>
              </HStack>
            </Button>
          </VStack>
        </PopOver>)}
    </HStack>);
};
