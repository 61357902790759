import React from 'react';
import { HStack, Input, InputGroup, InputLeftElement, StackDivider, useDisclosure, } from '@chakra-ui/react';
import PopOver from '../../surfaces/pop-over/index.web';
import ChooseCountry from '../../../screens/choose-country/index.web';
import Text from '../../content/text';
import FONTS from '../../../constants/fonts';
import Icon from '../../content/icon';
function PhoneInput({ isDisabled, placeholder, value, country, onChange, onCountryChange, autoFocus, }) {
    // Attributes
    const { isOpen: isOpenCountry, onToggle: onToggleCountry } = useDisclosure();
    // Functions
    function handleOnChooseCountry(value) {
        onCountryChange(value);
        onToggleCountry();
    }
    function paddingLeftByDialCodeLength() {
        switch (country?.dialCode.length) {
            case 3:
                return '6rem';
            case 4:
                return '6.5rem';
            case 5:
                return '7rem';
            default:
                return '6rem';
        }
    }
    // Render
    return (<InputGroup>
      <InputLeftElement width={paddingLeftByDialCodeLength()}>
        <HStack width={'full'} spacing={0} divider={<StackDivider />}>
          <PopOver from={<HStack padding={'0.75rem'}>
                <Text font={FONTS.T1.T16Px.Regular400.value}>
                  {country?.flag}
                </Text>
                <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {country?.dialCode}
                </Text>
                <Icon name="chevron-down" color={'#8F95B2'}/>
              </HStack>} placement="bottom-start" isOpen={isOpenCountry} onToggle={onToggleCountry}>
            <ChooseCountry country={country} onChooseCountry={handleOnChooseCountry}/>
          </PopOver>
        </HStack>
      </InputLeftElement>
      <StackDivider />
      <Input paddingLeft={paddingLeftByDialCodeLength()} autoFocus={autoFocus} type="tel" placeholder={placeholder} isDisabled={isDisabled} value={value} onChange={e => onChange(e.currentTarget?.value)}/>
    </InputGroup>);
}
export default PhoneInput;
