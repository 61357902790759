import React, { useState } from 'react';
import { tKeys } from '../../../../../translations/keys';
import UserSignStatus from '../user-sign-status/index.web';
import { Checkbox, HStack, Input, Stack, VStack } from '@chakra-ui/react';
import COLORS from '../../../../../constants/colors';
import Icon from '../../../../../components/content/icon';
import LoadingWrapper from '../../../../../components/utility/loading-wrapper/index.web';
import AvatarDetails from '../../../../../components/content/avatar-details';
import Text from '../../../../../components/content/text';
import { useTranslation } from 'react-i18next';
import FONTS from '../../../../../constants/fonts';
import Button from '../../../../../components/inputs/button/index.web';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { SignerStatus } from '../../../../../services/types';
import moment from 'moment';
const DocumentSectionSignedBy = ({ documentEntity, isUserAlreadySigned, peopleInTheCompany, isUpdateDocumentLoading, onAddSigners, userCanEditDocument, search, setSearch, culture, handleSendReminder, isSendingReminder, }) => {
    // Attributes
    const { t } = useTranslation();
    const [signerIds, setSignerIds] = useState([]);
    // Functions
    function isSignerSelected(signer) {
        return signerIds?.some(signerId => signerId === signer?.id);
    }
    function handleOnSelectSigner(signer) {
        let updateSignerIds;
        if (isSignerSelected(signer)) {
            updateSignerIds = signerIds.filter(signerId => signerId !== signer?.id);
        }
        else {
            updateSignerIds = signerIds.concat(signer?.id);
        }
        setSignerIds(updateSignerIds);
    }
    const calculatedSignatureProgression = documentEntity?.signers?.reduce((acc, signer) => acc + (signer?.status == SignerStatus[2] ? 1 : 0), 0);
    const isFullySigned = calculatedSignatureProgression === documentEntity?.signers?.length;
    // Render
    return (<VStack width={'full'} alignItems={'flex-start'} spacing={'1rem'} height={'full'}>
      {/* Title */}
      {documentEntity?.signerIds?.length > 0 ? (<Text font={FONTS.T1.T14Px.SemiBold600.value} color={moment(new Date())
                .add(7, 'days')
                .isAfter(moment(documentEntity.expireOn)) && !isFullySigned
                ? 'red'
                : 'inherit'}>
          {isFullySigned
                ? t(tKeys.common.signed)
                : t(tKeys.sign.document.expiration) +
                    moment(documentEntity.expireOn).locale(culture).fromNow()}
        </Text>) : (<Text font={FONTS.T1.T14Px.SemiBold600.value}>
          {t(tKeys.document.details.signers.title)}
        </Text>)}

      {/* Search */}
      {!documentEntity?.signerIds?.length ? (<>
          {userCanEditDocument && (<VStack alignItems={'flex-start'} width={'full'} height={'full'} justifyContent={'space-between'}>
              <VStack alignItems={'flex-start'} width={'full'}>
                <HStack width={'full'} bgColor={COLORS.SearchFullScreen.value} borderRadius={'.5rem'} px={'.5rem'}>
                  <Icon name="search" width={24} height={24}/>
                  <Input name="searchGlobal" value={search} onChange={e => setSearch(e?.currentTarget?.value)} placeholder={t(tKeys.document.details.signers.add)} borderWidth={0} pl={0} _focusVisible={{ borderWidth: 0 }} style={{ height: 40, width: '100%' }}/>
                  {search && <Icon name="close-cross" width={24} height={24}/>}
                </HStack>
                <VStack width={'full'} alignItems={'flex-start'} py={'.5rem'} minW={'18rem'} spacing={'.75rem'} pr={'.5rem'} maxH={'15.5rem'} overflow={'scroll'}>
                  <LoadingWrapper isLoading={isUpdateDocumentLoading}>
                    <>
                      {!!peopleInTheCompany &&
                    peopleInTheCompany.map(person => (<HStack justify="space-between" key={person.id} width={'full'} cursor={'pointer'}>
                            <AvatarDetails person={person?.person} avatarSize={'2.625rem'} showBoardRole/>
                            <Checkbox onChange={() => {
                            handleOnSelectSigner(person?.person);
                        }} isChecked={isSignerSelected(person?.person)}/>
                          </HStack>))}
                    </>
                  </LoadingWrapper>
                </VStack>
              </VStack>
              <Stack width={'full'} alignItems={'center'}>
                <Button kind={Kind.SECONDARY} onPress={() => onAddSigners(signerIds)} width={'full'} isLoading={isUpdateDocumentLoading}>
                  {t(tKeys.document.details.signers.add)}
                </Button>
              </Stack>
            </VStack>)}
        </>) : (<VStack alignItems={'flex-start'} width={'full'} overflow={'auto'} maxHeight={'25.75rem'} spacing={0}>
          {/* List of signers */}
          {documentEntity?.signers?.map(signer => (<UserSignStatus id={signer.personId} key={signer.personId} isUserAlreadySigned={isUserAlreadySigned} lastNotifiedOn={signer.lastNotifiedOn} culture={culture} handleSendReminder={handleSendReminder} isSendingReminder={isSendingReminder}/>))}
        </VStack>)}
    </VStack>);
};
export default DocumentSectionSignedBy;
