import React from 'react';
import { Pressable } from 'react-native';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import Icon from '../../content/icon';
import Text from '../../content/text';
import BaseModal from './base-modal/index.web';
import { Box, HStack, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, } from '@chakra-ui/react';
import Button from '../../inputs/button';
import { tKeys } from '../../../translations/keys';
import { Kind } from '../../inputs/button/constants/kind';
import { Shape } from '../../../constants/shape';
import { Size } from '../../inputs/button/constants/size';
import { t } from 'i18next';
const Modal = ({ title, children, isOpen, onClose, onPressCancelButton, actionButton, hasCancelButton, hasCloseButton, hasHeader = true, hasFooter = true, headerComponent, footerComponent, footerInformations, ...rest }) => {
    // Functions
    const renderModalHeader = () => {
        return (<HStack justify={'space-between'} paddingInline={'1.25rem'}>
        <Text color={COLORS.Text.T400.value} font={FONTS.T1.T14Px.SemiBold600.value}>
          {title}
        </Text>
        <HStack spacing={'1.5rem'}>
          {headerComponent}
          {hasCloseButton && (<Pressable onPress={onClose}>
              <Icon name="close-cross" width={24} height={24}/>
            </Pressable>)}
        </HStack>
      </HStack>);
    };
    const renderModalFooter = () => {
        if (footerComponent)
            return <>{footerComponent}</>;
        return (<HStack width={'100%'} justifyContent={'space-between'}>
        {footerInformations ? (<Stack flex="1" maxWidth={'100%'}>
            {footerInformations}
          </Stack>) : (
            // Un espace flexible vide pour s'assurer que les boutons restent à droite
            <Box flex="1"/>)}
        <HStack width="auto" spacing={8} justifyContent={'flex-end'}>
          {hasCancelButton && (<Button kind={Kind.WHITE} shape={Shape.DEFAULT} size={Size.DEFAULT} onPress={onPressCancelButton} textColor={COLORS.InputText.value}>
              {t(tKeys.accept_leaving.cta.cancel)}
            </Button>)}
          {actionButton}
        </HStack>
      </HStack>);
    };
    // Render
    return (<BaseModal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent maxH={'90vh'} overflow={'hidden'}>
        {hasHeader && (<ModalHeader paddingInline={'unset'} borderBottomWidth={'.0625rem'} borderBottomColor={COLORS.Line.value}>
            {renderModalHeader()}
          </ModalHeader>)}
        {children}
        {hasFooter && (<ModalFooter borderTopWidth={'.0625rem'} borderTopColor={COLORS.Line.value} w={'100%'}>
            {renderModalFooter()}
          </ModalFooter>)}
      </ModalContent>
    </BaseModal>);
};
export default Modal;
