import React from 'react';
import { HStack, Stack, Td, Tr, VStack } from '@chakra-ui/react';
import Avatar from '../../../../../../../components/content/avatar';
import getUserName from '../../../../../../../utils/getUserName';
import FONTS from '../../../../../../../constants/fonts';
import Text from '../../../../../../../components/content/text';
import moment from 'moment';
import { dateFormatFrench } from '../../../../../../../constants/date-format';
import COLORS from '../../../../../../../constants/colors';
import PopOver from '../../../../../../../components/surfaces/pop-over/index.web';
import { useTranslation } from 'react-i18next';
import TagFieldItem from '../../../../../calendar/components/inputs/tag-field/tag-item/index.web';
import { tKeys } from '../../../../../../../translations/keys';
import SelectBoardRole from '../../../../../../select-board-role/index.web';
import SelectMemberRole from '../../../../../../select-member-role/index.web';
import { getPlatformRoleLabel } from '../../../../../../../utils/platformRole';
import { useMemberListTableRowLogic } from './logic.web';
import Icon from '../../../../../../../components/content/icon';
import { MemberRowGroups } from './groups/index.web';
import Button from '../../../../../../../components/inputs/button';
import { Kind } from '../../../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../../../components/utility/loading/size';
export const MemberListTableRow = ({ enrolment, onClick, isAdmin, onEnrolmentUpdate, }) => {
    // Attributes
    const { t } = useTranslation();
    const { isBoardRoleOpen, isPlatformRoleOpen, handleBoardRoleToggle, handlePlatformRoleToggle, handleBoardRoleChange, handlePlatformRoleChange, isMoreMenuOpen, handleMoreMenuToggle, handleEnrolmentSuspend, personGroups, } = useMemberListTableRowLogic(enrolment, onEnrolmentUpdate);
    // Render
    return (<Tr width={'100%'}>
      {/* Avatar and full name */}
      <Td cursor={'pointer'} onClick={() => onClick(enrolment.personId)} role="group" pl={'.75rem'} pr={'.5rem'} py={'.75rem'}>
        <HStack spacing={'.625rem'}>
          <Avatar name={getUserName(enrolment?.person)} width={'1.625rem'} height={'1.625rem'} borderRadius={20} placeholderFont={FONTS.T1.T16Px.Medium500.value} imageSource={enrolment.person?.picture?.uri}/>
          <VStack alignItems={'flex-start'} spacing={0} maxWidth={'8rem'}>
            <Stack _groupHover={{ textDecor: 'underline' }}>
              <Text font={FONTS.T1.T12Px.Regular400.value}>
                {enrolment.person?.firstname}
              </Text>
            </Stack>
            <Stack _groupHover={{ textDecor: 'underline' }}>
              <Text font={FONTS.T1.T12Px.Regular400.value}>
                {enrolment.person?.lastname}
              </Text>
            </Stack>
          </VStack>
        </HStack>
      </Td>
      {/* Email */}
      <Td px={'.5rem'} py={'.75rem'}>
        <Stack maxWidth={'14rem'}>
          <Text font={FONTS.T1.T12Px.Regular400.value}>{enrolment.email}</Text>
        </Stack>
      </Td>
      {/* Phone */}
      <Td px={'.5rem'} py={'.75rem'}>
        <Text font={FONTS.T1.T12Px.Regular400.value}>
          {enrolment.person?.phone}
        </Text>
      </Td>
      {/* Groups */}
      <Td px={'.5rem'} py={'.75rem'}>
        {personGroups?.length > 0 ? (<MemberRowGroups groups={personGroups}/>) : (<Text font={FONTS.T1.T12Px.SemiBold600.value}>
            {t(tKeys.groups.placeholder.no_groups)}
          </Text>)}
      </Td>
      {/* Member since */}
      <Td px={'.5rem'} py={'.75rem'}>
        <Text font={FONTS.T1.T12Px.Regular400.value}>
          {moment(enrolment.startedOn).format(dateFormatFrench)}
        </Text>
      </Td>
      {/* Board role */}
      <Td px={'.5rem'} py={'.75rem'}>
        {isAdmin ? (<PopOver placement={'bottom-end'} from={<TagFieldItem value={enrolment.boardRoleName
                    ? t(enrolment.boardRoleName)
                    : t(tKeys.common.choose_role)} hasEndEnhancer/>} isOpen={isBoardRoleOpen} onToggle={handleBoardRoleToggle}>
            <SelectBoardRole selectedBoardRoleId={enrolment.boardRoleId} onChange={handleBoardRoleChange}/>
          </PopOver>) : (<Stack width={'fit-content'}>
            <TagFieldItem value={enrolment.boardRoleName
                ? t(enrolment.boardRoleName)
                : t(tKeys.common.no_role)}/>
          </Stack>)}
      </Td>
      {/* Platform role */}
      <Td px={'.5rem'} py={'.75rem'}>
        {isAdmin ? (<PopOver placement={'bottom-end'} from={<TagFieldItem value={getPlatformRoleLabel(enrolment.role)} hasEndEnhancer/>} isOpen={isPlatformRoleOpen} onToggle={handlePlatformRoleToggle}>
            <SelectMemberRole memberRole={enrolment.role} onChoose={handlePlatformRoleChange}/>
          </PopOver>) : (<Stack width={'fit-content'}>
            <TagFieldItem value={getPlatformRoleLabel(enrolment.role)}/>
          </Stack>)}
      </Td>
      {/* Menu */}
      {isAdmin && (<Td pr={'.75rem'} pl={'.5rem'} py={'.75rem'}>
          <PopOver placement={'bottom-end'} from={<Icon name="more-vertical" height={20} width={20}/>} isOpen={isMoreMenuOpen} onToggle={handleMoreMenuToggle} backgroundColorHover={COLORS.Tag.value}>
            <VStack minWidth={'17.5rem'} alignItems={'flex-start'} py={'.25rem'} spacing={0}>
              <Button onPress={handleEnrolmentSuspend} kind={Kind.TRANSPARENT} width={'full'} size={Size.COMPACT} needConfirmation={true}>
                <HStack width={'full'}>
                  <Icon name="delete-bin" width={30} height={30}/>
                  <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.Text.T800.value}>
                    {t(tKeys.members.suspend_member)}
                  </Text>
                </HStack>
              </Button>
            </VStack>
          </PopOver>
        </Td>)}
    </Tr>);
};
