/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/content/icon';
import Text from '../../components/content/text';
import Button from '../../components/inputs/button';
import { Kind } from '../../components/inputs/button/constants/kind';
import NavigationHeader from '../../components/navigation/navigation-header';
import Flex from '../../components/surfaces/flex';
import Page from '../../components/surfaces/page';
import Placeholder from '../../components/utility/placeholder';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { tKeys } from '../../translations/keys';
import useLogic from './logic.web';
import { Checkbox, VStack, Switch, Stack, HStack } from '@chakra-ui/react';
import AvatarDetails from '../../components/content/avatar-details';
import { PressableType } from '../../components/inputs/button/type';
import PressableWrapper from '../../components/inputs/button/wrapper';
import { Size } from '../../components/utility/loading/size';
import Modal from '../../components/surfaces/modal/index.web';
import MenuItem from '../../components/pickers/menu/menu-item/index.web';
import ShareInMessagesListHeader from '../share-in-messages/components/content/share-in-messages-list-header/index.web';
import { checkboxControl, switchControl } from '../../styles/react-style';
import Input from '../../components/inputs/input/index.web';
import Tabs from '../../components/navigation/tabs';
import Tab from '../../components/navigation/tabs/tab';
import { ShareEventTabs } from './types.web';
const ShareEvent = ({ isOpen, onClose, onCloseCreateMessage, shareInMessageEntity, }) => {
    // Attributes
    const { form, allMembersInCompany, isModuleAvailable, channels, isChannelsLoading, getParticipant, onChannelPress, isCompanyEnrolementsLoading, isCreateGroup, onChangeSwitchCreateGroup, isCreateButtonDisabled, onSelectMember, handleOnCreateGroup, handleOnPressMember, isCreateMessageLoading, setSelectedTab, selectedTab, tabsOptions, isEventPublished, } = useLogic(shareInMessageEntity, onCloseCreateMessage);
    const { t } = useTranslation();
    // Handle tab change
    const handleOnPressTab = (activeKey) => {
        setSelectedTab(activeKey);
    };
    const renderMembers = ({ item: person }) => {
        return (<PressableWrapper pressableType={PressableType.SCALE} activeScale={1} onPress={async () => await handleOnPressMember(person)} style={{ width: '100%' }}>
        <Flex justify="space-between" align="center">
          <AvatarDetails person={person} showStatus avatarSize={'2.625rem'} showBoardRole/>
          {isCreateGroup ? (<Checkbox onChange={() => onSelectMember(person)} _checked={checkboxControl}/>) : (<Icon name="chevron-right" width={24} height={24}/>)}
        </Flex>
      </PressableWrapper>);
    };
    if (!isModuleAvailable && isOpen) {
        return (<Page backgroundColor={COLORS.White.T500.value}>
        <NavigationHeader divider leftIconName="close-cross">
          <Text font={FONTS.T1.T16Px.SemiBold600.value} color={COLORS.Black.T400.value}>
            {t(tKeys.messages.moduleDisabled.title)}
          </Text>
        </NavigationHeader>
        <Flex>
          <Placeholder imageType="monocle-emoji" title={t(tKeys.messages.moduleDisabled.title)} subTitle={t(tKeys.messages.moduleDisabled.subtitle)} maxWidth={320}/>
        </Flex>
      </Page>);
    }
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.common.share)} hasCloseButton actionButton={<Button size={Size.MINI} kind={Kind.SECONDARY} isDisabled={isCreateButtonDisabled} onPress={handleOnCreateGroup} isLoading={isCreateMessageLoading}>
          <Text font={FONTS.T1.T12Px.SemiBold600.value} color={isCreateButtonDisabled
                ? COLORS.Black.T200.value
                : COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
            {t(tKeys.common.share)}
          </Text>
        </Button>}>
      <VStack alignItems={'flex-start'} spacing={'1.5rem'} width={'full'} px={'0.75rem'} pt={'.5rem'} overflow={'auto'} py={'1.25rem'}>
        {/* Tabs for switching between Email and Chat */}
        <Tabs activeKey={selectedTab} onChange={activeKey => handleOnPressTab(activeKey)}>
          {tabsOptions.map(o => (<Tab key={o.value} title={o.label} onSelect={() => handleOnPressTab(o.value)} font={FONTS.T1.T12Px.Medium500.value}/>))}
        </Tabs>
        {/* Tab content */}
        {parseInt(selectedTab.toString()) ===
            ShareEventTabs['share_event.tabs.chat'] && (<>
            <ShareInMessagesListHeader allMembersInCompany={allMembersInCompany} shareInMessageEntity={shareInMessageEntity} channels={channels} form={form} getParticipant={getParticipant} isChannelsLoading={isChannelsLoading} isCompanyEnrolementsLoading={isCompanyEnrolementsLoading} onChannelPress={onChannelPress}/>
            <Stack width={'full'} spacing={'1rem'}>
              <MenuItem startEnhancer={<Icon name="members" width={36} height={36}/>} title={t(tKeys.messages.createNewGroup.title)} titleFont={FONTS.T1.T14Px.Medium500.value} endEnhancer={<Switch size={'md'} _checked={switchControl} isChecked={isCreateGroup} onChange={onChangeSwitchCreateGroup}/>}/>
              {isCreateGroup && (<Input value={form.groupName.value} onChange={e => form.groupName.onChange(e.currentTarget.value)} placeholder={t(tKeys.messages.groupName.placeholder)}/>)}
              {(isCreateGroup || allMembersInCompany?.length > 0) && (<Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {t(tKeys.more.page.menu.members.menu_title)}
                </Text>)}
              {allMembersInCompany?.map((person, index) => {
                return renderMembers({
                    item: person,
                    index,
                    separators: undefined,
                });
            })}
            </Stack>
          </>)}

        {parseInt(selectedTab.toString()) ===
            ShareEventTabs['share_event.tabs.email'] && (<>
            <Text font={FONTS.T1.T14Px.Regular400.value}>
              {isEventPublished
                ? t(tKeys.share_event.email.title)
                : t(tKeys.share_event.email.not_published)}
            </Text>
            {isEventPublished && (<VStack>
                <Checkbox textColor={COLORS.Text.T800.value} isDisabled={true} isChecked={true} width="100%">
                  <HStack width="100%">
                    <Text color={COLORS.Text.T800.value} width="25rem" maxWidth="100%">
                      {t(tKeys.share_event.email.list.basic_info)}
                    </Text>
                  </HStack>
                </Checkbox>
                <Checkbox isDisabled={true} isChecked={true} width="100%">
                  <HStack width="100%">
                    <Text width="35rem" maxWidth="100%" numberOfLines={2}>
                      {t(tKeys.share_event.email.list.calendar)}
                    </Text>
                  </HStack>
                </Checkbox>
                <Checkbox isDisabled={true} isChecked={true} width="100%">
                  <HStack width="100%">
                    <Text width="25rem" maxWidth="100%">
                      {t(tKeys.share_event.email.list.agenda)}
                    </Text>
                  </HStack>
                </Checkbox>

                <Checkbox isDisabled={true} isChecked={true} width="100%">
                  <HStack width="100%">
                    <Text width="35rem" maxWidth="100%">
                      {t(tKeys.share_event.email.list.documents_link)}
                    </Text>
                  </HStack>
                </Checkbox>
              </VStack>)}
            {/* <Text font={FONTS.T1.T14Px.Regular400.value} mt="1.5rem" mb="0.5rem">
          Comment
        </Text>
        <Input
          placeholder="Write a comment..."
          width="100%"
          height="4rem"
        
        /> */}
          </>)}
      </VStack>
    </Modal>);
};
export default ShareEvent;
