import React from 'react';
import COLORS from '../../../../../constants/colors';
import styles from './style';
import useLogic from './logic.web';
import { t } from 'i18next';
import FormControl from '../../../../../components/inputs/form-control';
import { tKeys } from '../../../../../translations/keys';
import Text from '../../../../../components/content/text';
import Icon from '../../../../../components/content/icon';
import FONTS from '../../../../../constants/fonts';
import Button from '../../../../../components/inputs/button';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../components/utility/loading/size';
import { DocumentTypes } from '../../../../../components/surfaces/document-modal/props';
import TagField from '../../../calendar/components/inputs/tag-field';
import Modal from '../../../../../components/surfaces/modal/index.web';
import { HStack, Stack, StackDivider, Textarea, VStack, Image, } from '@chakra-ui/react';
import TagFieldItem from '../../../calendar/components/inputs/tag-field/tag-item/index.web';
import PopOver from '../../../../../components/surfaces/pop-over/index.web';
import SelectFileType from '../../../../select-file-type/index.web';
import Input from '../../../../../components/inputs/input/index.web';
import FilterDateAndHours from '../../../../../components/pickers/filter-hours';
import SelectPersonGroupSection from '../../../calendar/components/content/select-multiple-people-groups/index.web';
const CreateDocument = ({ isOpen, onClose, selectedFile, refetchDocuments, }) => {
    // Attributes
    const { form, dataType, imageBase64, documentTypes, values, disabledSaveButton, isCreateDocumentLoading, handleOnSubmit, isOpenFileType, onToggleFileType, handleOnChangeFileType, groupsInTheCompany, handleGroupSelect, handlePersonSelect, handleSearchChange, isGroupSelected, isPersonSelected, peopleInTheCompany, isGroupAlreadyInEvent, isPersonAlreadyInEvent, } = useLogic(selectedFile, onClose, refetchDocuments);
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={dataType === DocumentTypes.FILE
            ? t(tKeys.home.more.repository.create_document.title)
            : t(tKeys.home.more.repository.upload_image.title)} actionButton={<Button size={Size.MINI} kind={Kind.SECONDARY} isDisabled={disabledSaveButton} isLoading={isCreateDocumentLoading} onPress={handleOnSubmit}>
          <Text font={FONTS.T1.T12Px.SemiBold600.value} color={disabledSaveButton
                ? COLORS.Text.T300.value
                : COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
            {t(tKeys.common.save)}
          </Text>
        </Button>} footerInformations={<HStack bgColor={COLORS.Warning.T100.value} px={'.5rem'} py={'.25rem'} spacing={'.5rem'} borderRadius={'.25rem'} maxWidth={'75%'}>
          <Stack alignSelf={'flex-start'}>
            <Icon name="warning" height={16} width={16}/>
          </Stack>
          <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Warning.T600.value} numberOfLines={8}>
            {selectedFile?.eventId
                ? t(tKeys.repository.create_document.alert.event)
                : t(tKeys.repository.create_document.alert.no_event)}
          </Text>
        </HStack>} hasCloseButton>
      <VStack alignItems={'flex-start'} width={'full'} overflow={'auto'} pb={'2rem'}>
        {/* Image preview */}
        <Stack style={{
            ...styles.imageContainer,
            backgroundColor: dataType === DocumentTypes.FILE
                ? COLORS.Line.value
                : COLORS.White.T500.value,
            width: '100%',
        }} alignItems={'center'} justifyContent={'center'}>
          <Image height={'100%'} maxHeight={'16.5625rem'} objectFit={'contain'} src={dataType === DocumentTypes.FILE
            ? require('../../../../../assets/images/pdf_preview.png')
            : imageBase64}/>
        </Stack>
        <VStack style={styles.mainContainer} alignItems={'flex-start'} width={'full'} divider={<StackDivider />}>
          {/* Title Input */}
          <VStack alignItems={'flex-start'} px={'1rem'} spacing={'1rem'} py={'.5rem'} width={'full'}>
            <FormControl label={t(tKeys.common.title)} error={form?.title?.meta?.error} spacing={2}>
              <Input value={form?.title.value} onChange={e => {
            form?.title.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.title.placeholder)} autoFocus/>
            </FormControl>
          </VStack>

          {/* File type */}
          <TagField title={t(tKeys.home.more.repository.create_document.selected_file_type
            .title)} startEnhancer={<Icon name="layers"/>} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={t(values?.fileType?.name)}/>} isPortal={false} isOpen={isOpenFileType} onToggle={onToggleFileType}>
                <SelectFileType documentTypes={documentTypes} onChange={handleOnChangeFileType} selectedDocumentTypeId={values?.fileType?.id}/>
              </PopOver>} error={form?.fileType?.meta?.error}/>

          {/* Issued on */}
          <Stack width={'full'} p={'1rem'}>
            <FilterDateAndHours {...form?.issuedOn} isTimeEnabled={false} title={t(tKeys.common.issued_on)} startEnhancer={<Icon name="calendar-blue"/>} placeholderEnabled/>
          </Stack>

          {/* Permissions */}
          <SelectPersonGroupSection groupsInCompany={groupsInTheCompany ?? []} peopleInCompany={peopleInTheCompany} isGroupSelected={isGroupSelected} isPersonSelected={isPersonSelected} onChangeSearchValue={handleSearchChange} onGroupSelect={handleGroupSelect} onPersonSelect={handlePersonSelect} title={t(tKeys.common.permissions)} isGroupAlreadyInEvent={isGroupAlreadyInEvent} isPersonAlreadyInEvent={isPersonAlreadyInEvent}/>

          {/* Optional description */}
          <HStack p={'1rem'} spacing={'.75rem'} width={'full'}>
            <Stack alignSelf={'flex-start'}>
              <Icon name="sections" width={32} height={32}/>
            </Stack>

            <FormControl error={form?.description?.meta?.error}>
              <Textarea value={form?.description.value} onChange={e => {
            form?.description.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.common.description.placeholder)} fontSize={'0.875rem'} fontWeight={400} pl={'.25rem'}/>
            </FormControl>
          </HStack>
        </VStack>
      </VStack>
    </Modal>);
};
export default CreateDocument;
