import React from 'react';
import Icon from '../../components/content/icon';
import COLORS from '../../constants/colors';
import useLogic from './logic.web';
import FONTS from '../../constants/fonts';
import Text from '../../components/content/text';
import { t } from 'i18next';
import FormControl from '../../components/inputs/form-control';
import { tKeys } from '../../translations/keys';
import TagField from '../home/calendar/components/inputs/tag-field';
import styles from './style';
import Button from '../../components/inputs/button';
import { Size } from '../../components/inputs/button/constants/size';
import { Kind } from '../../components/inputs/button/constants/kind';
import Modal from '../../components/surfaces/modal/index.web';
import { HStack, Stack, StackDivider, Textarea, VStack, } from '@chakra-ui/react';
import PopOver from '../../components/surfaces/pop-over/index.web';
import TagFieldItem from '../home/calendar/components/inputs/tag-field/tag-item/index.web';
import SelectFileType from '../select-file-type/index.web';
import Input from '../../components/inputs/input/index.web';
import FilterDateAndHours from '../../components/pickers/filter-hours/index.web';
import SelectPersonGroupSection from '../home/calendar/components/content/select-multiple-people-groups/index.web';
const DocumentEdit = ({ isOpen, onClose, onCloseDetails, editDocumentEntity, refetchDocument, }) => {
    // Logic
    const { form, handleOnPressDelete, handleOnSubmit, isUpdateDocumentLoading, isDeleteDocumentLoading, values, documentTypes, onChangeTypeId, isOpenFileType, onToggleFileType, groupsInTheCompany, handleGroupSelect, handlePersonSelect, handleSearchChange, isGroupSelected, isPersonSelected, peopleInTheCompany, search, } = useLogic(editDocumentEntity, onClose, onCloseDetails, refetchDocument);
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={onClose} title={t(tKeys.home.more.document['edit-document'].title)} actionButton={<HStack spacing={'1rem'}>
          <Button kind={Kind.TRANSPARENT} onPress={handleOnPressDelete} isLoading={isDeleteDocumentLoading} backgroundColorPerso="white" borderColor="red" borderWidth={1} needConfirmation={true}>
            <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.Error.T500.value}>
              {t(tKeys.home.more.document['edit-document']['delete-button-title'])}
            </Text>
          </Button>
          <Button size={Size.MINI} kind={Kind.SECONDARY} isLoading={isUpdateDocumentLoading} onPress={handleOnSubmit}>
            <Text font={FONTS.T1.T12Px.SemiBold600.value} color={COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
              {t(tKeys.common.save)}
            </Text>
          </Button>
        </HStack>} hasCloseButton>
      <VStack alignItems={'flex-start'} width={'full'} overflow={'auto'} pb={'2rem'}>
        <VStack style={styles.mainContainer} alignItems={'flex-start'} width={'full'} divider={<StackDivider />}>
          {/* Title Input */}
          <VStack alignItems={'flex-start'} px={'1rem'} spacing={'1rem'} pb={'.5rem'} pt={'1rem'} width={'full'}>
            <FormControl label={t(tKeys.common.title)} error={form?.title?.meta?.error} spacing={3}>
              <Input value={form?.title.value} onChange={e => {
            form?.title.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.tasks.create_tasks.title.placeholder)} autoFocus/>
            </FormControl>
          </VStack>

          {/* File type */}
          <TagField title={t(tKeys.home.more.repository.create_document.selected_file_type
            .title)} startEnhancer={<Icon name="layers"/>} endEnhancer={<PopOver placement={'bottom-end'} from={<TagFieldItem value={t(values?.typeId)}/>} isPortal={false} isOpen={isOpenFileType} onToggle={onToggleFileType}>
                <SelectFileType documentTypes={documentTypes} onChange={onChangeTypeId} selectedDocumentTypeId={values?.typeId}/>
              </PopOver>} error={form?.typeId?.meta?.error}/>

          {/* Issued on */}
          <Stack width={'full'} p={'1rem'}>
            <FilterDateAndHours {...form?.issuedOn} isTimeEnabled={false} title={t(tKeys.common.issued_on)} startEnhancer={<Icon name="calendar-blue"/>} placeholderEnabled/>
          </Stack>

          {/* Permissions */}
          <SelectPersonGroupSection groupsInCompany={groupsInTheCompany ?? []} peopleInCompany={peopleInTheCompany} isGroupSelected={isGroupSelected} isPersonSelected={isPersonSelected} onChangeSearchValue={handleSearchChange} onGroupSelect={handleGroupSelect} onPersonSelect={handlePersonSelect} searchValue={search} title={t(tKeys.common.permissions)}/>

          {/* Optional description */}
          <HStack p={'1rem'} spacing={'.75rem'} width={'full'}>
            <Icon name="sections" width={32} height={32}/>

            <FormControl error={form?.description?.meta?.error}>
              <Input value={form?.description.value} onChange={e => {
            form?.description.onChange?.(e?.currentTarget?.value);
        }} placeholder={t(tKeys.common.description.placeholder)}/>
            </FormControl>
          </HStack>

          {/* Optional update description */}
          <VStack alignItems={'flex-start'} width={'full'} p={'1rem'}>
            <HStack spacing={'.75rem'} width={'full'}>
              <Icon name="sections" width={32} height={32}/>
              <Text font={FONTS.T1.T14Px.Medium500.value} color={COLORS.Text.T400.value} numberOfLines={2}>
                {t(tKeys.home.more.document['edit-document']['extra-description'])}
              </Text>
            </HStack>
            <FormControl error={form?.changeLog?.meta?.error}>
              <Textarea value={form?.changeLog.value} onChange={e => {
            form?.changeLog.onChange?.(e?.currentTarget?.value);
        }} placeholder={'Write something...'} fontSize={'0.875rem'} fontWeight={400} pl={'.25rem'}/>
            </FormControl>
          </VStack>
        </VStack>
      </VStack>
    </Modal>);
};
export default DocumentEdit;
