import { generateMinutesPreview } from '../services/online/calendar/calendar';
export async function downloadFile(url) {
    if (!url || url === '')
        return;
    const link = document.createElement('a');
    let newUrl = url;
    const resp = await fetch(url)
        .then(async (elt) => await elt.blob())
        .catch(e => console.log('Fail to fetch ressource because:', e));
    if (resp) {
        newUrl = URL.createObjectURL(resp);
        const filename = url.split('/')[url.split('/').length - 1].split('?')[0];
        link.setAttribute('download', filename);
    }
    link.href = newUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
export async function downloadFileWithRename(url, filename) {
    if (!url || url === '')
        return;
    const link = document.createElement('a');
    let newUrl = url;
    const resp = await fetch(url)
        .then(async (elt) => await elt.blob())
        .catch(e => console.log('Fail to fetch ressource because:', e));
    if (resp) {
        newUrl = URL.createObjectURL(resp);
        link.setAttribute('download', filename);
    }
    link.href = newUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
export async function downloadMinutes(eventId, culture) {
    const fileUrl = await generateMinutesPreview(eventId, {
        culture,
    });
    await downloadFile(fileUrl);
}
export async function downloadFileToPDF(fileName, pdftronWebviewerInstance, withAnnotations, watermark) {
    try {
        const xfdfString = await pdftronWebviewerInstance.Core.documentViewer
            .getAnnotationManager()
            .exportAnnotations();
        if (watermark) {
            // Add watermark into the document
            await pdftronWebviewerInstance.Core.documentViewer.setWatermark({
                diagonal: {
                    fontSize: 40,
                    fontFamily: 'sans-serif',
                    color: '#00a0df',
                    opacity: 15,
                    text: watermark,
                },
            });
            // Download pdf with watermark
            await pdftronWebviewerInstance.UI.downloadPdf({
                filename: fileName,
                includeAnnotations: withAnnotations,
                xfdfString,
            });
            // Remove the watermark from the document
            await pdftronWebviewerInstance.Core.documentViewer.setWatermark({
                diagonal: {
                    text: null,
                },
            });
            return;
        }
        await pdftronWebviewerInstance.UI.downloadPdf({
            filename: fileName,
            includeAnnotations: withAnnotations,
            xfdfString,
        });
    }
    catch (error) {
        console.error('Erreur lors du téléchargement du fichier PDF :', error);
    }
}
