import React from 'react';
import useLogic from './logic.web';
import FONTS from '../../../../constants/fonts';
import Tabs from '../../../../components/navigation/tabs';
import Tab from '../../../../components/navigation/tabs/tab';
import EventDetailsButtonsHeader from '../components/inputs/event-details-buttons-header/index.web';
import EventDetailsInformations from '../components/content/event-details-informations/index.web';
import LoadingWrapper from '../../../../components/utility/loading-wrapper';
import EventDetailsAgenda from '../components/content/event-details-agenda/index.web';
import EventDetailsFiles from '../components/content/event-details-files/index.web';
import EventDetailsResolutions from '../components/content/event-details-resolutions/index.web';
import EventDetailsTasks from '../components/content/event-details-tasks/index.web';
import { EventDetailsTabs } from './types.web';
import { Button, HStack, VStack } from '@chakra-ui/react';
import COLORS from '../../../../constants/colors';
import CreateEvent from '../create-event/index.web';
import GenerateEventDocument from '../generate-event-document/index.web';
import EventDetailsProxy from '../event-details-proxy/index.web';
import Page from '../../../../components/surfaces/page/index.web';
import NavigationHeader from '../../../../components/navigation/navigation-header/index.web';
import Icon from '../../../../components/content/icon';
import { PublishInformationsCard } from './components/publish-card/index.web';
import { EventDetailsMainInformations } from './components/main-informations/index.web';
import { EventDetailsTasksSection } from './components/event-tasks-section/index.web';
import EventDetailsInvitees from '../components/content/event-details-invitees/index.web';
import { EventDetailsPreviewMinutes } from './components/preview-minutes/index.web';
import { tKeys } from '../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EventDetailsAISummary from '../components/content/event-details-ai-summary/index.web';
import ShareEvent from '../../../share-event/index.web';
const EventDetails = () => {
    // Attributes
    const { t } = useTranslation();
    const { event, tabsOption, handleOnPressTab, selectedTab, handlePublishEvent, isPublishEventLoading, findTimezoneName, isEventLoading, handleEditEventPress, handlePresenceStatus, showPreviewMinutes, isAdmin, isCurrentUserInvited, isCurrentUserPresent, isOpenEditEvent, onCloseEditEvent, isOpenShareEvent, handleOnCloseShareInMessage, shareInMessageEntity, isOpenPreviewMinutes, onClosePreviewMinutes, isOpenProxy, onCloseProxy, proxyEntityParams, handleOnPressMessage, refetchEventData, eventId, onGoBack, onCloseShareEvent, handleDeleteEventPress, isDeleteLoading, currentUserInvitee, canModifyInviteesPresence, canRemoveInvitees, eventIsPast, eventTasks, refetchEventTasks, isTasksLoading, enrolment, eventShouldBeRecorded, eventWillBeRecorded, lastPublishedFormated, } = useLogic();
    // Render function
    function RenderTabsContent() {
        switch (parseInt(selectedTab.toString())) {
            case EventDetailsTabs?.['calendar.event_details.tabs.agenda']: {
                return (<DndProvider backend={HTML5Backend}>
            <EventDetailsAgenda event={event} isCurrentUserInvited={isCurrentUserInvited} isCurrentUserPresent={isCurrentUserPresent} refetchEventTasks={refetchEventTasks} isAdmin={isAdmin}/>
          </DndProvider>);
            }
            case EventDetailsTabs?.['calendar.event_details.tabs.files']: {
                return <EventDetailsFiles event={event} isAdmin={isAdmin}/>;
            }
            case EventDetailsTabs?.['calendar.event_details.tabs.tasks']: {
                return (<EventDetailsTasks event={event} onClose={onGoBack} isAdmin={isAdmin}/>);
            }
            case EventDetailsTabs?.['calendar.event_details.tabs.resolutions']: {
                return <EventDetailsResolutions event={event} isAdmin={isAdmin}/>;
            }
            case EventDetailsTabs?.['event.details.AI-summary']: {
                return (<EventDetailsAISummary isAdmin={isAdmin} eventSummary={event.summary}/>);
            }
            default:
                return <></>;
        }
    }
    // Render
    return (<Page backgroundColor={COLORS.BG.value}>
      <NavigationHeader justifyTitle="flex-start" startEnhancer={<Button p={'.25rem'} bgColor={COLORS.Tag.value} borderRadius={'.5rem'} onClick={onGoBack}>
            <Icon name="chevron-left-default" height={15} width={15}/>
          </Button>} endEnhancer={<EventDetailsButtonsHeader handlePublishEvent={async () => await handlePublishEvent(event?.id)} handleEditEventPress={handleEditEventPress} isPublishEventLoading={isPublishEventLoading} isEventPublished={!!event?.publishedOn} isEventLoading={isEventLoading} isInviteeOnEvent={!!event?.inviteeIds?.length} isAdmin={isAdmin} handleOnPressMessage={handleOnPressMessage} event={event} onClose={onGoBack} isDeleteLoading={isDeleteLoading} onDeleteEvent={handleDeleteEventPress} lastPublishedOn={lastPublishedFormated}/>}>
        {t(tKeys.calendar.header.title)}
      </NavigationHeader>
      <VStack width={'full'} alignItems={'flex-start'} px={'5rem'} py={'1rem'} overflow={'auto'} height={'full'}>
        <LoadingWrapper isLoading={isEventLoading}>
          <>
            {!event?.publishedOn && <PublishInformationsCard />}
            <HStack width={'full'} spacing={'1.25rem'} height={'full'}>
              <VStack width={'full'} alignItems={'flex-start'} height={'full'}>
                <EventDetailsMainInformations event={event} isAdmin={isAdmin} isCurrentUserInvited={isCurrentUserInvited} 
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    handlePresenceStatus={handlePresenceStatus} currentUserInvitee={currentUserInvitee}/>
                <EventDetailsInformations eventTimezone={findTimezoneName(event?.timeZoneId)} eventUrl={event?.locationUrl} eventLocalization={event?.location} eventShouldBeRecorded={eventShouldBeRecorded} eventWillBeRecorded={eventWillBeRecorded}/>
                <VStack width={'full'} height={'full'} alignItems={'flex-start'} borderRadius={'.5rem'} minHeight={'25rem'} maxHeight={'35rem'} borderWidth={1} borderColor={COLORS.Line.value} bgColor={COLORS.White.T500.value} overflow={'hidden'}>
                  <Tabs activeKey={selectedTab} onChange={activeKey => handleOnPressTab(activeKey)}>
                    {tabsOption?.map(o => (<Tab key={o?.value} title={o?.label} onSelect={() => handleOnPressTab(o?.value)} font={FONTS.T1.T12Px.Medium500.value}/>))}
                  </Tabs>
                  <LoadingWrapper isLoading={isEventLoading}>
                    <RenderTabsContent />
                  </LoadingWrapper>
                </VStack>
              </VStack>
              <VStack width={'60%'} alignItems={'flex-start'} height={'full'} justifyContent={'flex-start'}>
                {eventIsPast ? (<>
                    {showPreviewMinutes && (<EventDetailsPreviewMinutes eventId={eventId}/>)}
                  </>) : (!!event?.publishedOn && (<EventDetailsTasksSection eventId={event?.id} refetchEvent={refetchEventData} eventTasks={eventTasks} isTasksLoading={isTasksLoading} refetchEventTasks={refetchEventTasks}/>))}
                <EventDetailsInvitees canModifyInviteesPresence={canModifyInviteesPresence} event={event} refetchEvent={refetchEventData} canRemoveInvitees={canRemoveInvitees} isAdmin={isAdmin} isEventPast={eventIsPast}/>
              </VStack>
            </HStack>
          </>
        </LoadingWrapper>
      </VStack>
      {/* Modals */}
      <CreateEvent onClose={onCloseEditEvent} isOpen={isOpenEditEvent} event={event} onCloseDeleteEvent={onGoBack}/>
      <ShareEvent isOpen={isOpenShareEvent} onClose={onCloseShareEvent} onCloseCreateMessage={handleOnCloseShareInMessage} shareInMessageEntity={shareInMessageEntity}/>
      <GenerateEventDocument isOpen={isOpenPreviewMinutes} onClose={onClosePreviewMinutes} eventId={eventId}/>
      <EventDetailsProxy isOpen={isOpenProxy} onClose={onCloseProxy} proxyEntity={proxyEntityParams} enrolment={enrolment}/>
    </Page>);
};
export default EventDetails;
