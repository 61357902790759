import { useFormik } from 'formik';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { useCompaniesContext } from '../../contexts/companies';
import { ModulesEnum } from '../../enum/Modules';
import useRouter from '../../hooks/router/useRouter';
import { getReadChannelsQueryKey, getReadMessagesQueryKey, useCreateChannel, useCreateMessage, useReadChannels, } from '../../services/online/chat/chat';
import { useReadEnrolments } from '../../services/online/enrolments/enrolments';
import { useReadMe } from '../../services/online/people/people';
import { tKeys } from '../../translations/keys';
import { createForm } from '../../utils/formik';
import getStaleTime from '../../utils/getStaleTime';
import getUserName from '../../utils/getUserName';
import { ShareEventTabs, } from './types.web';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../components/content/use-toast/index.web';
import { useShareEvent } from '../../services/online/calendar/calendar';
const useLogic = (shareInMessagesEntity, onClose) => {
    // Attributes
    const { push } = useRouter();
    const { selectedCompanyId, modules } = useCompaniesContext();
    const queryClient = useQueryClient();
    const toast = useToast();
    const [selectedTab, setSelectedTab] = useState(0);
    const isModuleAvailable = useMemo(() => {
        return modules?.some(m => m.name === ModulesEnum.DISCUSS);
    }, [modules]);
    const [isCreateGroup, setIsCreateGroup] = useState(false);
    const [isCreateMessageLoading, setIsCreateMessageLoading] = useState(false);
    // Form
    const { values, ...rest } = useFormik({
        initialValues: {
            messageContent: '',
            selectedMembers: [],
            groupName: '',
        },
        onSubmit: undefined,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: false,
        validateOnBlur: false,
    });
    const form = createForm(values, rest);
    const tabsOptions = [
        {
            label: t(ShareEventTabs[0]),
            value: 0,
        },
        {
            label: t(ShareEventTabs[1]),
            value: 1,
        },
    ];
    // Queries
    const { mutateAsync: createChannel } = useCreateChannel();
    const { mutateAsync: createMessage } = useCreateMessage();
    const { mutateAsync: shareEventEmail } = useShareEvent();
    const { data: me } = useReadMe({ query: { staleTime: getStaleTime() } });
    const { data: companyEnrolements, isLoading: isCompanyEnrolementsLoading } = useReadEnrolments({
        'company-id': selectedCompanyId,
        'page-size': 1000,
    }, {
        query: {
            enabled: !!selectedCompanyId,
            staleTime: getStaleTime(),
        },
    });
    const { data: channels, isLoading: isChannelsLoading } = useReadChannels({
        'company-id': selectedCompanyId,
    }, { query: { enabled: !!selectedCompanyId } });
    const allMembersInCompany = useMemo(() => {
        const allMembers = companyEnrolements?.items?.map(enrolement => enrolement?.person);
        // Return all member for create a group
        if (isCreateGroup)
            return allMembers?.filter(p => p?.id !== me?.person?.id);
        if (channels) {
            return allMembers?.filter(p => {
                const participantsIds = channels
                    ?.filter(c => !c?.isGroup)
                    .flatMap(c => c?.participants?.map(p => p?.personId));
                return !participantsIds?.includes(p?.id);
            });
        }
        return allMembers?.filter(p => p?.id !== me?.person?.id);
    }, [companyEnrolements, channels, isCreateGroup]);
    // Functions
    function onChangeSwitchCreateGroup() {
        // Remove all participants previously selected if we don't create a group
        if (isCreateGroup)
            form.selectedMembers.onChange?.([]);
        setIsCreateGroup(!isCreateGroup);
    }
    const isEventPublished = shareInMessagesEntity?.data?.publishedOn !== null;
    async function handleOnSubmit(channel, selectedMember, isEmail = false) {
        setIsCreateMessageLoading(true);
        const nameConversation = getUserName(selectedMember);
        const participants = [selectedMember, me?.person];
        const participantIds = participants?.map(member => member?.id);
        const createMessageBody = {
            creatorId: me?.person?.id,
            content: values?.messageContent,
            postId: shareInMessagesEntity?.isPost
                ? shareInMessagesEntity?.data?.id
                : null,
            documentId: shareInMessagesEntity?.isDocument
                ? shareInMessagesEntity?.data?.id
                : null,
            documentVersionId: shareInMessagesEntity?.data?.versionId ?? null,
            eventId: shareInMessagesEntity?.isEvent
                ? shareInMessagesEntity?.data?.id
                : null,
        };
        const params = {
            channel: channel ?? {
                name: getUserName(selectedMember),
                companyId: selectedCompanyId,
                isGroup: false,
                participantIds: [selectedMember?.id, me?.person?.id],
                creatorId: me?.person?.id,
            },
        };
        try {
            // if a channel exist already then send to this channel
            if (channel !== undefined) {
                await handleCreateMessage({ ...createMessageBody }, { ...params });
                return;
            }
            // If the channel does'nt exist athats means taht the user never talks with the person
            // so we need to create a new channel and send the message in the new created channel
            await createChannel({
                data: {
                    companyId: selectedCompanyId,
                    name: nameConversation,
                    isGroup: false,
                    participantIds,
                    creatorId: me?.person?.id,
                },
            }, {
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSuccess: async (channelId) => await handleCreateMessage({ ...createMessageBody }, {
                    channel: { ...params, id: channelId?.value },
                    person: selectedMember,
                }),
                onError: err => {
                    setIsCreateMessageLoading(false);
                    console.error('Error when creating a channel when share content : ', err);
                    toast({
                        status: 'error',
                        title: t(tKeys.validation.sharing_content.error),
                    });
                },
            });
        }
        catch (err) {
            setIsCreateMessageLoading(false);
            toast({
                status: 'error',
                title: t(tKeys.validation.sharing_content.error),
            });
            console.error('Error when share content : ', err);
        }
    }
    async function handleCreateMessage(data, params) {
        // Send the message to the new created channel or exisiting one
        await createMessage({
            data: {
                ...data,
                channelId: params?.channel?.id,
            },
        }, {
            onSuccess: () => {
                onClose();
                void queryClient.invalidateQueries(getReadChannelsQueryKey({ 'company-id': selectedCompanyId }));
                void queryClient.invalidateQueries(getReadMessagesQueryKey({ 'channel-id': params.channel?.id }));
                push('/company/home/messages', {
                    companyId: selectedCompanyId,
                    ...params,
                });
                setIsCreateMessageLoading(false);
            },
            onError: err => {
                setIsCreateMessageLoading(false);
                console.error('Error when creating a message when share content : ', err);
                toast({
                    status: 'error',
                    title: t(tKeys.validation.sharing_content.error),
                });
            },
        });
    }
    function getParticipant(channel) {
        return channel?.participants?.find(p => p?.personId !== me?.person?.id)
            ?.personId;
    }
    async function onChannelPress(channel) {
        if (isCreateMessageLoading)
            return;
        return await handleOnSubmit(channel);
    }
    async function handleOnPressMember(person) {
        if (isCreateMessageLoading || isCreateGroup)
            return;
        return await handleOnSubmit(undefined, person);
    }
    function isMemberSelected(member) {
        return values?.selectedMembers?.some(id => id === member?.id);
    }
    const onSelectMember = (member) => {
        let updatedMembers;
        if (isMemberSelected(member)) {
            updatedMembers = values?.selectedMembers?.filter(selectedMember => selectedMember?.id !== member.id);
        }
        else {
            updatedMembers = values?.selectedMembers?.concat(member);
        }
        form?.selectedMembers?.onChange(updatedMembers);
    };
    async function handleOnCreateGroup() {
        if (isCreateMessageLoading)
            return;
        if (parseInt(selectedTab.toString()) ===
            ShareEventTabs['share_event.tabs.email']) {
            setIsCreateMessageLoading(true);
            await shareEventEmail({
                eventId: shareInMessagesEntity?.data?.id,
                data: {
                    shareAgenda: true,
                    shareParticipants: true,
                    shareDocuments: true,
                    shareMinutes: true,
                },
            }, {
                onSuccess: () => {
                    setIsCreateMessageLoading(false);
                    onClose();
                    toast({
                        status: 'success',
                        title: t(tKeys.validation.sharing_event.success),
                        duration: 2000,
                    });
                },
                onError: err => {
                    setIsCreateMessageLoading(false);
                    console.error('Error when sharing event by email : ', err);
                    toast({
                        status: 'error',
                        title: t(tKeys.validation.sharing_content.error),
                    });
                },
            });
            return;
        }
        // Create channel if not exist
        const newChannel = {
            companyId: selectedCompanyId,
            name: values?.groupName,
            isGroup: true,
            participantIds: values?.selectedMembers?.map(members => {
                return members?.id;
            }),
            creatorId: me?.person?.id,
        };
        void createChannel({
            data: newChannel,
        }, {
            onSuccess: channelId => {
                const channel = { ...newChannel, id: channelId.value };
                void handleOnSubmit(channel);
            },
        });
    }
    const isCreateButtonDisabled = !isEventPublished ||
        (parseInt(selectedTab.toString()) ===
            ShareEventTabs['share_event.tabs.chat'] &&
            (!isCreateGroup ||
                !values?.selectedMembers?.length ||
                values.groupName === ''));
    // Return
    return {
        handleOnSubmit,
        form,
        allMembersInCompany,
        isModuleAvailable,
        channels,
        isChannelsLoading,
        getParticipant,
        onChannelPress,
        isCompanyEnrolementsLoading,
        onChangeSwitchCreateGroup,
        isCreateGroup,
        isCreateButtonDisabled,
        onSelectMember,
        handleOnPressMember,
        handleOnCreateGroup,
        isCreateMessageLoading,
        selectedTab,
        setSelectedTab,
        tabsOptions,
        isEventPublished,
    };
};
export default useLogic;
