import { t } from 'i18next';
import React from 'react';
import Text from '../../../../../../components/content/text';
import PlaceholderNoInvitees from '../../../../../../components/utility/placeholder-no-invitees';
import FONTS from '../../../../../../constants/fonts';
import { tKeys } from '../../../../../../translations/keys';
import getStatusFromInvitee from '../../../../../../utils/getStatusFromInvitee';
import EventDetailsInformationsInvitees from '../../../event-details-informations-invitees/index.web';
import { Stack, VStack } from '@chakra-ui/react';
import COLORS from '../../../../../../constants/colors';
import { useEventDetailsInviteesLogic } from './logic.web';
import { BusyIndicator } from '../../../../../../components/utility/busy-indicator';
import { EventDetailsInviteesGroupRow } from './group-row/index.web';
const EventDetailsInvitees = ({ canModifyInviteesPresence, event, refetchEvent, canRemoveInvitees, isAdmin, isEventPast, }) => {
    // Logic
    const { isPopoverOpen, onPopoverToggle, popoverRef, handleSetSearch, peopleNotInvited, handleAddInvitee, isLoadingUpdateEvent, getPresenceStatusFromInvitee, sortedInviteesList, groupsNotInvited, handleGroupAddToEvent, isLoadingAddGroupToEvent, handleGroupRemoveFromEvent, peopleInvited, } = useEventDetailsInviteesLogic(event, refetchEvent);
    // Render
    return (<VStack alignItems={'flex-start'} width={'full'} py={'1.25rem'} spacing={'1rem'} bgColor={COLORS.White.T500.value} borderRadius={'.5rem'} borderWidth={1} borderColor={COLORS.Line.value}>
      <BusyIndicator isEmpty={!event?.invitees?.length && !event?.groupIds?.length} placeholder={<PlaceholderNoInvitees />}>
        {/* Members and groups list */}
        <VStack alignItems={'flex-start'} width={'full'} overflow={'auto'} spacing={'1.375rem'}>
          {/* Groups list */}
          {event.groupIds.length > 0 && (<VStack alignItems={'flex-start'} width={'full'} spacing={'1.375rem'}>
              <Stack px={'1.25rem'}>
                <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {t(tKeys.common.groups_number, {
                value: event.groupIds.length,
            })}
                </Text>
              </Stack>
              <VStack alignItems={'flex-start'} width={'full'}>
                {event.groupIds.map(groupId => (<EventDetailsInviteesGroupRow key={groupId} canRemoveGroup={false} groupId={groupId} 
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onGroupRemove={handleGroupRemoveFromEvent}/>))}
              </VStack>
            </VStack>)}
          {/* Members list */}
          {sortedInviteesList.length > 0 && (<VStack alignItems={'flex-start'} width={'full'} spacing={'1.375rem'}>
              <Stack px={'1.25rem'}>
                <Text font={FONTS.T1.T14Px.SemiBold600.value}>
                  {t(tKeys.common.members_number, {
                value: sortedInviteesList.length,
            })}
                </Text>
              </Stack>
              <VStack alignItems={'flex-start'} width={'full'} spacing={0}>
                {sortedInviteesList?.map((invitee, index) => {
                return (<EventDetailsInformationsInvitees person={peopleInvited?.find(elt => elt.id === invitee?.personId)} personId={invitee?.personId} userStatus={getStatusFromInvitee(invitee?.personId, !!invitee?.proxyId, event?.presentAttendeeIds, event?.absentAttendeeIds, event?.remoteAttendeeIds, invitee)} proxyPersonId={invitee?.proxyId} key={index} canModifyInviteePresence={canModifyInviteesPresence} event={event} refetchEvent={refetchEvent} tagPresenceStyle={getPresenceStatusFromInvitee(invitee)} canRemoveInvitees={false} isAdmin={isAdmin} isEventPast={isEventPast}/>);
            })}
              </VStack>
            </VStack>)}
        </VStack>
      </BusyIndicator>
    </VStack>);
};
export default EventDetailsInvitees;
