import React, { useState } from 'react';
import AvatarDetails from '../../../../../components/content/avatar-details';
import FONTS from '../../../../../constants/fonts';
import COLORS from '../../../../../constants/colors';
import { tKeys } from '../../../../../translations/keys';
import { useTranslation } from 'react-i18next';
import { HStack, VStack } from '@chakra-ui/react';
import Tag from '../../../../../components/content/tag/index.web';
import Text from '../../../../../components/content/text';
import moment from 'moment';
import PopOver from '../../../../../components/surfaces/pop-over/index.web';
import Button from '../../../../../components/inputs/button';
import { Kind } from '../../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../../components/inputs/button/constants/size/index.web';
import Icon from '../../../../../components/content/icon';
const UserSignStatus = ({ id, isUserAlreadySigned, lastNotifiedOn, culture, handleSendReminder, isSendingReminder, }) => {
    // Attributes
    const { t } = useTranslation();
    console.log('lastNotifiedOn', lastNotifiedOn);
    const lastNotifiedOnFormated = moment(lastNotifiedOn)
        .locale(culture)
        .fromNow();
    const [isOpen, setIsOpen] = useState(false);
    // Render
    return (<HStack justifyContent={'space-between'} width={'100%'} py={'.5rem'}>
      <HStack width={'12rem'}>
        <AvatarDetails avatarSize={'2.25rem'} avatarFont={FONTS.T1.T10Px.Medium500.value} showBoardRole personId={id}/>
      </HStack>

      {/* Tag when already signed */}
      {isUserAlreadySigned(id) ? (<Tag backgroundColor={COLORS.Success.T100.value} iconName="check" iconColor={COLORS.Success.T500.value} color={COLORS.Text.T400.value} style={{ borderRadius: 100 }}>
          {t(tKeys.common.signed)}
        </Tag>) : (<PopOver isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} from={<Tag backgroundColor={COLORS.Warning.T100.value} iconName="navigation-bar-messages-default" fillIconColor={COLORS.Text.T400.value} color={COLORS.Text.T400.value} style={{ borderRadius: 100 }} hasEndEnhancer={true}>
              <></>
            </Tag>} placement="bottom">
          <VStack spacing={'.5rem'} width={'full'}>
            <Text style={{ textAlign: 'left', padding: '1rem 0.75rem' }} width={'100%'}>
              {lastNotifiedOnFormated}
            </Text>
            <Button kind={Kind.TRANSPARENT} onPress={() => handleSendReminder(id)} isLoading={isSendingReminder} size={Size.MINI}>
              <Text font={FONTS.T1.T12Px.Medium500.value} color={COLORS.Text.T800.value}>
                {t(tKeys.sign.document.resend)}
              </Text>
              <Icon name="send-message" width={20} height={20}/>
            </Button>
          </VStack>
        </PopOver>)}
    </HStack>);
};
export default UserSignStatus;
