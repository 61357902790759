import { t } from 'i18next';
import React from 'react';
import { FlatList } from 'react-native';
import Icon from '../../../../../../components/content/icon';
import Button from '../../../../../../components/inputs/button';
import { Kind } from '../../../../../../components/inputs/button/constants/kind';
import DocumentCard from '../../../../../../components/surfaces/document-card-event';
import LoadingWrapper from '../../../../../../components/utility/loading-wrapper';
import Placeholder from '../../../../../../components/utility/placeholder';
import COLORS from '../../../../../../constants/colors';
import { tKeys } from '../../../../../../translations/keys';
import useLogic from './logic.web';
import styles from './style';
import { Box, Checkbox, HStack, Stack, VStack } from '@chakra-ui/react';
import CreateDocument from '../../../../more/repository/create-document/index.web';
import DropzoneInput from '../../../../../../components/inputs/dropzone/index.web';
import { Size } from '../../../../../../components/utility/loading/size';
const EventDetailsFiles = ({ 
// eslint-disable-next-line react/prop-types
event, 
// eslint-disable-next-line react/prop-types
isAdmin, }) => {
    // Attributes
    const { documents, isDocumentLoading, handleCreateDocument, handleDeleteDocument, handleOnPressDocument, handleOnDrop, selectedFile, isOpenCreateDocument, isCreateDocumentLoading, onCloseCreateDocument, refetchDocs, isLoadingDeleteDocument, handleOnSelectDocument, selectedDocuments, handleDownloadBundle, isLoadingCreateBundle, canBeDownloaded, } = useLogic(event);
    // Functions
    const ListHeaderComponent = () => {
        if (!documents?.[0]?.items?.length) {
            return <></>;
        }
        return (<VStack w="full" py={'1.25rem'}>
        {isAdmin && (<DropzoneInput isLoading={isCreateDocumentLoading} onDrop={handleOnDrop}/>)}
      </VStack>);
    };
    function ListEmptyComponent() {
        if (isDocumentLoading) {
            return <></>;
        }
        return (<Placeholder imageType="monocle-emoji" hasImage={false} title={t(tKeys.calendar.files.placeholder.title)} subTitle={isAdmin
                ? t(tKeys.calendar.files.placeholder.subTitle)
                : t(tKeys.calendar.files.placeholder.subTitle_invitees)} endEnhancer={isAdmin && (<DropzoneInput isLoading={isCreateDocumentLoading} onDrop={handleOnDrop}/>)}/>);
    }
    function renderDocumentsItems({ item, index, }) {
        return (<Box px={'1rem'}>
        <HStack spacing={'0.5rem'} alignItems="center">
          {/* Tick box for download */}
          <Checkbox name={item.id.toString()} onChange={() => {
                handleOnSelectDocument(item.id);
            }} isChecked={selectedDocuments.includes(item.id)} color={COLORS.Success.T500.value}/>
          <Box key={index} cursor={'pointer'} width={'100%'} onClick={() => handleOnPressDocument(item?.id)}>
            <DocumentCard startEnhancer={<Stack style={styles.documentIcon}>
                  <Icon name="files" height={14} width={14} fill={COLORS.InputText.value}/>
                </Stack>} subtitle={item.agendaItemTitle} endEnhancer={<HStack spacing={'.75rem'} onClick={e => e.stopPropagation()}>
                  {/* Delete document */}
                  <Button kind={Kind.OUTLINE} size={Size.MINI} needConfirmation={true} onPress={() => {
                    handleDeleteDocument(item.id); // Suppression du document
                }}>
                    <Icon name="cross" height={10} width={10} fill={COLORS.Text.T500.value}/>
                  </Button>
                </HStack>} width={'100%'}>
              {item?.title ?? item?.file?.filename}
            </DocumentCard>
          </Box>
        </HStack>
      </Box>);
    }
    // Render
    return (<Box width={'full'} pb={'2rem'} overflow="scroll">
      {/* Documents list */}

      <LoadingWrapper isLoading={isDocumentLoading || isLoadingDeleteDocument}>
        <VStack>
          {/* Header */}
          <HStack justifyContent={'flex-end'} // Align content to the right
     alignItems={'center'} // Ensure items are vertically centered
     px={'1rem'} py={'1.25rem'} width={'full'}>
            {canBeDownloaded && (<Button onPress={() => {
                handleDownloadBundle();
            }} isLoading={isLoadingCreateBundle} isDisabled={!selectedDocuments.length} kind={Kind.OUTLINE}>
                {t(tKeys.common.download)}
              </Button>)}
          </HStack>
          <FlatList data={documents?.flatMap(i => i?.items) ?? []} renderItem={renderDocumentsItems} ListHeaderComponent={ListHeaderComponent} ListEmptyComponent={ListEmptyComponent} style={{ width: '100%' }}/>
        </VStack>
      </LoadingWrapper>

      {/* Modal */}
      <CreateDocument isOpen={isOpenCreateDocument} onClose={onCloseCreateDocument} selectedFile={selectedFile} refetchDocuments={refetchDocs}/>
    </Box>);
};
export default EventDetailsFiles;
