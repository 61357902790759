import React, { useState } from 'react';
import Shapes, { Shape } from '../../../constants/shape';
import KINDS, { Kind } from './constants/kind';
import { Size } from './constants/size';
import styles from './style';
import { Button as ChakraButton, Text } from '@chakra-ui/react';
import { WEBSIZES } from './constants/size/index.web';
import COLORS from '../../../constants/colors';
import { t } from 'i18next';
import { tKeys } from '../../../translations/keys';
const Button = ({ id, children, endEnhancer, isDisabled, isLoading, kind = Kind.PRIMARY, onPress, shape = Shape.DEFAULT, size = Size.DEFAULT, startEnhancer, width, borderWidth = 0, borderColor, backgroundColorPerso, textColor, opacity, right = 0, textWidth = '100%', minWidth, height, needConfirmation, }) => {
    // Attributes
    const { backgroundColor, disabledBackgroundColor, disabledTextColor, textColor: textColorFromKind, } = KINDS[kind];
    const { borderRadius } = Shapes[shape];
    const { paddingVertical, font, paddingHorizontal, iconSize } = WEBSIZES[size];
    textColor = textColor ?? textColorFromKind;
    const { fontFamily, fontSize, fontWeight } = font;
    const isOutline = kind === Kind.OUTLINE;
    const [isConfirmed, setIsConfirmed] = useState(false);
    const buttonText = isConfirmed ? 'Are you sure?' : children; // Change button text to "Are you sure?" upon confirmation
    const handlePress = () => {
        if (needConfirmation && !isConfirmed) {
            setIsConfirmed(true); // First click triggers confirmation
        }
        else {
            onPress?.(); // Second click triggers the actual action
        }
    };
    const handleBlur = () => {
        if (isConfirmed) {
            setIsConfirmed(false); // Reset the confirmation state if the button loses focus
        }
    };
    // Functions
    const renderChildren = () => {
        const buttonText = isConfirmed
            ? t(tKeys.common['confirm-delete'])
            : children; // Change button text to "Are you sure?" upon confirmation
        const buttonTextColor = isConfirmed
            ? '#FFFFFF'
            : isDisabled
                ? disabledTextColor
                : textColor; // Text color changes to white if confirmed
        switch (typeof buttonText) {
            // Text
            case 'string': {
                return (<Text fontFamily={fontFamily} fontSize={fontSize} fontWeight={fontWeight} color={buttonTextColor} width={textWidth}>
            {buttonText}
          </Text>);
            }
            // ReactElement
            default:
                return <>{buttonText}</>;
        }
    };
    // Render
    return (<ChakraButton id={id} overflow={'hidden'} onClick={handlePress} onBlur={handleBlur} // Triggered when the button loses focus
     {...styles.mainContainer} backgroundColor={isConfirmed
            ? 'red' // Change background color to red upon confirmation
            : backgroundColorPerso ??
                (isDisabled ? disabledBackgroundColor : backgroundColor)} py={paddingVertical} borderRadius={borderRadius} borderColor={isOutline ? borderColor ?? COLORS.BGFullscreen.value : borderColor} borderWidth={isOutline ? 1 : borderWidth} width={width ?? 'fit-content'} opacity={opacity} right={right} isDisabled={isDisabled} isLoading={isLoading} px={paddingHorizontal} minW={minWidth} height={height} _focus={{
            boxShadow: 'none',
            backgroundColor: isConfirmed ? 'red' : backgroundColor, // Maintain red color on hover if in confirmation state
        }} _hover={{
            boxShadow: 'none',
            backgroundColor: isConfirmed ? 'red' : backgroundColor, // Maintain red color on hover if in confirmation state
        }} cursor={'pointer'} textAlign={'center'}>
      {startEnhancer?.({
            width: iconSize,
            height: iconSize,
            color: textColor,
        })}

      <>{renderChildren()}</>
      {endEnhancer?.({
            width: iconSize,
            height: iconSize,
            color: textColor,
        })}
    </ChakraButton>);
};
export default Button;
