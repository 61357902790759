import FONTS from '../../../../../constants/fonts';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Size = {
    DEFAULT: 'DEFAULT',
    SMALL: 'SMALL',
    LARGE: 'LARGE',
    EXTRA_LARGE: 'EXTRA_LARGE',
    COMPACT: 'COMPACT',
    MINI: 'MINI',
};
export const WEBSIZES = {
    [Size.DEFAULT]: {
        paddingVertical: '0.75rem',
        paddingHorizontal: '1.25rem',
        font: FONTS.T1.T12Px.SemiBold600.value,
        iconSize: '1.5rem',
    },
    [Size.EXTRA_LARGE]: {
        paddingVertical: '1rem',
        font: FONTS.T1.T12Px.SemiBold600.value,
        iconSize: '1.5rem',
    },
    [Size.LARGE]: {
        paddingVertical: '0.5rem',
        font: FONTS.T1.T12Px.SemiBold600.value,
        paddingHorizontal: '0.875rem',
        iconSize: '1.5rem',
    },
    [Size.SMALL]: {
        paddingVertical: '.5rem',
        font: FONTS.T1.T12Px.SemiBold600.value,
        paddingHorizontal: '.75rem',
        iconSize: '1.25rem',
    },
    [Size.COMPACT]: {
        paddingVertical: '0.375rem',
        paddingHorizontal: '0.375rem',
        font: FONTS.T1.T12Px.SemiBold600.value,
        iconSize: '1.5rem',
    },
    [Size.MINI]: {
        paddingVertical: '.1rem',
        paddingHorizontal: '0.75rem',
        font: FONTS.T1.T12Px.SemiBold600.value,
        iconSize: '1.5rem',
    },
};
