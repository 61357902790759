import { Role } from '../../../../../../../services/types';
import { useReadEventTypes } from '../../../../../../../services/online/calendar/calendar';
import { useDisclosure } from '@chakra-ui/react';
import { useCompaniesContext } from '../../../../../../../contexts/companies';
import useRouter from '../../../../../../../hooks/router/useRouter';
export const useCardNextEventLogic = () => {
    // Attributes
    const { push } = useRouter();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const { isOpen: isOpenCreateEvent, onOpen: onOpenCreateEvent, onClose: onCloseCreateEvent, } = useDisclosure();
    const isAdmin = selectedEnrolment?.role === Role?.[0];
    console.log('isAdmin', isAdmin);
    console.log('selectedEnrolment', selectedEnrolment);
    // Queries
    const { data: allEventsTypes } = useReadEventTypes({
        'company-id': selectedCompanyId,
    }, { query: { enabled: !!selectedCompanyId } });
    // Functions
    function handleRedirectCalendarPage() {
        push('/company/home/calendar', { companyId: selectedCompanyId });
    }
    // Return
    return {
        isAdmin,
        isOpenCreateEvent,
        onOpenCreateEvent,
        onCloseCreateEvent,
        handleRedirectCalendarPage,
        allEventsTypes,
    };
};
