import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../../../components/content/text';
import Button from '../../../../components/inputs/button';
import { Kind } from '../../../../components/inputs/button/constants/kind';
import { Size } from '../../../../components/inputs/button/constants/size';
import COLORS from '../../../../constants/colors';
import FONTS from '../../../../constants/fonts';
import { tKeys } from '../../../../translations/keys';
import CreateEventBody from './create-event-body/index.web';
import useLogic from './logic.web';
import Modal from '../../../../components/surfaces/modal/index.web';
import { HStack } from '@chakra-ui/react';
const CreateEvent = ({ isOpen, onClose, event, onCloseDeleteEvent, additionalFunctionOnCreate, }) => {
    // Attributes
    const { t } = useTranslation();
    const { form, isCreateLoading, filterDateOpen, setFilterDateOpen, values, isEditMode, onDeleteEventPress, isDeleteLoading, isUpdateEventLoading, handleOnSubmit, handleOnChangeSearch, searchInvitees, peopleInTheCompany, handleOnSelectInvitee, isInviteeSelected, isCreateDisabled, handleClose, groupsInTheCompany, handleGroupSelect, isGroupSelected, } = useLogic(onClose, event, onCloseDeleteEvent, additionalFunctionOnCreate);
    // Render
    return (<Modal size={'2xl'} isOpen={isOpen} onClose={handleClose} title={isEditMode
            ? t(tKeys.calendar.editEvent.title)
            : t(tKeys.calendar.createEvent.title)} hasCloseButton actionButton={<HStack>
          {isEditMode && (<Button kind={Kind.TRANSPARENT} onPress={onDeleteEventPress} isLoading={isDeleteLoading} backgroundColorPerso="white" borderColor="red" needConfirmation={true} borderWidth={1}>
              <Text font={FONTS.T1.T14Px.SemiBold600.value} color={COLORS.Error.T500.value}>
                {t(tKeys.calendar.editEvent.deleteEvent)}
              </Text>
            </Button>)}
          <Button size={Size.MINI} kind={Kind.SECONDARY} isDisabled={isCreateDisabled} isLoading={isCreateLoading || isUpdateEventLoading} onPress={handleOnSubmit}>
            <Text font={FONTS.T1.T12Px.SemiBold600.value} color={!isCreateDisabled && COLORS.White.T500.value} style={{ paddingHorizontal: 20 }}>
              {isEditMode ? t(tKeys.common.save) : t(tKeys.common.create)}
            </Text>
          </Button>
        </HStack>}>
      <CreateEventBody form={form} filterDateOpen={filterDateOpen} setFilterDateOpen={setFilterDateOpen} values={values} isEditMode={isEditMode} onDeleteEventPress={onDeleteEventPress} isDeleteLoading={isDeleteLoading} onChangeSearchInvitees={handleOnChangeSearch} searchInvitees={searchInvitees} peopleInTheCompany={peopleInTheCompany} handleOnSelectInvitee={handleOnSelectInvitee} isInviteeSelected={isInviteeSelected} groupsInTheCompany={groupsInTheCompany ?? []} onGroupSelect={handleGroupSelect} isGroupSelected={isGroupSelected}/>
    </Modal>);
};
export default CreateEvent;
